import {reaction} from 'mobx';
import {useEffect, useState} from 'react';

export function useMobxValue<T, K extends keyof T>(store: T, key: K) {
  const [value, setValue] = useState(store[key]);
  useEffect(() => {
    const disposer = reaction(
      () => store[key],
      (val, prev) => {
        if (val !== prev) {
          setValue(val);
        }
      }
    );
    return disposer;
  }, [store, key]);

  return value;
}
