import {ApiFetchStore} from '../api';
import type {GroupInfo} from '@/Features/NewOrder/NewTicket/fees';
export const mtGroupStore = new ApiFetchStore<Partial<GroupInfo>, GroupInfo>({
  name: 'MT Group',
  fetchUrl: '/api/group',
  throttleMs: 300000,
  transformData: function (data) {
    return {
      MarginFreeMode: data.data.MarginFreeMode,
      Commissions: data.data.Commissions,
      Currency: data.data.Currency,
    };
  },
});
