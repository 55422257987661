import {useSafeAreaInsets} from 'react-native-safe-area-context';
export * from './useCachedResources';
export * from './useColors';
export * from './useKeyboard';
export function useSafeAreaStyles() {
  const insets = useSafeAreaInsets();
  return {
    safeAreaTop: {
      paddingTop: insets.top,
    },
    safeAreaBottom: {
      paddingBottom: insets.bottom,
    },
    safeAreaLeft: {
      paddingLeft: insets.left,
    },
    safeAreaRight: {
      paddingRight: insets.right,
    },
    safeAreaHorizontal: {
      paddingLeft: insets.left,
      paddingRight: insets.right,
    },
    safeAreaVertical: {
      paddingTop: insets.top,
      paddingBottom: insets.bottom,
    },
    safeArea: {
      paddingTop: insets.top,
      paddingBottom: insets.bottom,
      paddingLeft: insets.left,
      paddingRight: insets.right,
    },
  };
}
