import Navigation from './navigation';
import {appUiStore, getFreshToken, getQueryParams, mobileApi, useAppLang} from './Lib';
import {QueryProvider} from './Lib/queries/Provider';
import {appSettings} from './Lib/storage';
import {Spinner} from './components/Spinner';
import {requestPermission} from './firebase/messaging';
import {useAppTheme} from './hooks';
import {priceEngine} from './Lib/PriceEngine';
import {themeOverride} from '@/themes';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import './index.css';
import {observer} from 'mobx-react-lite';
import {useWindowDimensions, View} from 'react-native';
import {lazy, Suspense, useEffect, useState} from 'react';
import {captureException} from '@sentry/react';
import {UIContext} from '@amana/ui';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AppGatewayStack = createNativeStackNavigator();

function Gateway() {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    const params = getQueryParams(window.location.href);
    if (params?.token) {
      appSettings.set('refresh_token', params.token);
    }
    const refreshToken = appSettings.getString('refresh_token');
    if (refreshToken) {
      getFreshToken(refreshToken).finally(() => {
        setReady(true);
      });
    } else {
      setReady(true);
    }
  }, []);

  if (!ready) return <Spinner />;

  return (
    <QueryProvider>
      <WithLanguage />
    </QueryProvider>
  );
}

const Protected = LazyHoc(lazy(() => import('./ProtectedApp')));
const Public = LazyHoc(lazy(() => import('./Features/PublicApp')));

function LazyHoc(Component: any) {
  return () => (
    <Suspense fallback={null}>
      <Component />
    </Suspense>
  );
}

const WithLanguage = observer(() => {
  const {token} = appUiStore;
  const [viewPort, setViewPort] = useState(window.innerWidth > 768 ? 'desktop' : 'mobile');
  useEffect(() => {
    if (!token) return;
    requestPermission().then(fcmToken => {
      if (fcmToken)
        mobileApi.post('/register-device', {firebase_token: fcmToken}).catch(captureException);
    });
  }, [token]);

  useEffect(() => {
    const handleResize = () => {
      const view = window.innerWidth > 768 ? 'desktop' : 'mobile';
      if (view === 'mobile') {
        document.querySelector('#contain')?.classList.add('mobile');
      } else {
        document.querySelector('#contain')?.classList.remove('mobile');
      }
      setViewPort(view);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(
    function gatewayUseEffect() {
      token && priceEngine.isConnected() === false && priceEngine.setup(token);
    },
    [token]
  );

  return (
    <>
      <View style={{flex: 1}} key={viewPort}>
        <SyncWindowSize />
        <SafeAreaProvider>
          <Navigation>
            <AppGatewayStack.Navigator screenOptions={{headerShown: false}}>
              {token ? (
                <AppGatewayStack.Screen name="RootApp" component={Protected} />
              ) : (
                <AppGatewayStack.Screen name="AuthApp" component={Public} />
              )}
            </AppGatewayStack.Navigator>
          </Navigation>
        </SafeAreaProvider>
      </View>
      <ToastContainer />
    </>
  );
});

const WithColorScheme = observer(() => {
  const [locale] = useAppLang();
  const [theme] = useAppTheme();
  return (
    <UIContext.Provider value={{theme, locale: locale as 'en', overrideTheme: themeOverride}}>
      <Gateway />
    </UIContext.Provider>
  );
});

function SyncWindowSize() {
  const {width, height} = useWindowDimensions();
  useEffect(() => {
    appUiStore.setAppDimensions({width, height});
  }, [width, height]);
  return null;
}

export default WithColorScheme;
