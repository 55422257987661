import {appUiStore} from './AppUi';
import {accountStore} from './AccountStore';
import {positionStore} from './PositionStore';
import {ordersStore} from './OrderStore';
import {watchlistStore} from './WatchListStore';
import {settingsStore} from './SettingsStore';
import {passStorage} from '../../Features/PublicApp/screens/lead-phase/LoginScreen/LoginScreen';
import {appSettings, storage} from '../storage';
import {queryClient} from '../queries';
import {priceEngine} from '../PriceEngine';
import {symbolStore} from '@/Lib';
import {MARKETING_URL} from '@/Env/index.web';
import {signOut} from '@/Features/PublicApp/screens/lead-phase/LoginScreen/utils/social-reset';
import {runInAction} from 'mobx';
import {clear} from 'suspend-react';
import {Platform} from 'react-native';
// KeepStorage is only used in automated logouts like when api return 401
export function logout(keepStorage: boolean | null = false) {
  signOut();
  appSettings.delete('refresh_token');
  runInAction(() => {
    if (keepStorage === false) {
      console.log('CLEARING STORAGE');
      passStorage.delete('LAST_PASSWORD');
      symbolStore.symbolDb?.clear();
      storage.clearAll();
    }
    priceEngine.dispose();
    accountStore.clear();
    positionStore.clear();
    ordersStore.clear();
    appUiStore.clear();
    watchlistStore.clear();
    queryClient.clear();
    symbolStore.clear();
    clear();
    queryClient.clear();
  });
  if (Platform.OS === 'web') {
    // localStorage.clear(); don't clear entire local storage only remove some keys.
    storage.delete('PREVIOUS_SESSION_TOKEN');
    sessionStorage.clear();
    if (!__DEV__)
      document.location.href = `${MARKETING_URL}/webtrader?event=logout&lang=${settingsStore.lang}`;
  }
}

// export default new RootStore();
