import {Alert, AlertData} from './entities/Alert';
import {symbolStore} from './SymbolStore';
import {mobileApi} from '../api';
import {makeAutoObservable, runInAction} from 'mobx';

export class AlertStore {
  byId: Record<string, Alert> = {};
  bySymbol: Record<string, Alert[]> = {};

  constructor() {
    makeAutoObservable(this, {_fetching: false});
  }

  toArray() {
    return Object.values(this.byId) || [];
  }

  fetch() {
    console.log('Getting alerts');
    return mobileApi
      .get<AlertData[]>('/price-alerts')
      .then(r => r.data)
      .catch(() => {
        return [];
      });
  }

  _fetching?: Promise<unknown>;
  async setup() {
    this._fetching =
      this._fetching ||
      new Promise(async resolve => {
        const alerts = await this.fetch();
        runInAction(() => {
          this.byId = {};
          this.bySymbol = {};
          alerts.forEach(entity => {
            if (!symbolStore.exists(entity.symbol)) {
              const syncedSymbol = symbolStore.getSymbolSync(entity.symbol);
              if (!syncedSymbol) {
                return;
              }
            }
            const alert = new Alert(entity);
            this.byId[alert.data.id] = alert;
            if (!this.bySymbol[alert.data.symbol]) {
              this.bySymbol[alert.data.symbol] = [];
            }
            this.bySymbol[alert.data.symbol].push(alert);
          });
        });
        resolve(true);
      }).finally(() => {
        this._fetching = undefined;
      });

    return this._fetching;
  }

  async create(data: Omit<AlertData, 'id' | 'user_id' | 'created_at' | 'updated_at'>) {
    return mobileApi.post(`/price-alerts`, data).then(this.setup.bind(this));
  }

  async remove(id: string) {
    const alert = this.byId[id];
    if (alert) {
      delete this.byId[id];
      this.bySymbol[alert.data.symbol].filter(a => a.data.id !== id);
    }
    return mobileApi.delete(`/price-alerts/` + id).then(this.setup.bind(this));
  }

  clearStore() {
    this.byId = {};
    this.bySymbol = {};
  }
}

export const alertStore = new AlertStore();
