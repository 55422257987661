import {Order} from './entities/Order';
import {api} from '../api';
import {symbolStore} from '@/Lib';
import {makeAutoObservable, observable} from 'mobx';
import {captureException} from '@sentry/react';
import type {Tapi} from '../websocket';

export class OrdersStore {
  private working: Promise<unknown> | null = null;
  positions: Order[] = [];
  bySymbol: Record<string, Order[]> = {};
  confirmOrder: Order | null = null;
  editMode = false;

  clearConfirmOrder = (reset = false) => {
    if (this.confirmOrder && reset) {
      this.confirmOrder.openPrice = this.confirmOrder?.initialOpenPrice;
    }
    this.confirmOrder = null;
  };
  setConfirmOrder = (order: Order, editMode = false) => {
    if (!editMode && order.initialOpenPrice === order.openPrice) return;
    this.confirmOrder = order;
    this.editMode = editMode;
  };
  reset() {
    this.positions = [];
    this.bySymbol = {};
    this.editMode = false;
  }
  getTotalMargin() {
    return this.positions.reduce((acc, cur) => acc + cur.RequiredMargin, 0);
  }
  getTotalProfit() {
    return this.positions.reduce((acc, cur) => acc + cur.Profit, 0);
  }
  getSymbolVolume(symbol: string) {
    let i = 0;

    for (let p in this.positions) {
      const elm = this.positions[p];
      if (elm.symbol === symbol) {
        i += elm.Units * (elm.type % 2 === 0 ? 1 : -1);
      }
    }

    return i;
  }

  get top() {
    return [...this.positions]
      .sort((a, b) => {
        const p1 = Math.abs(Number(a.FillDistance) / a.openPrice);
        const p2 = Math.abs(Number(b.FillDistance) / b.openPrice);
        if (p1 < p2) {
          return -1;
        }
        if (p1 > p2) {
          return 1;
        }
        return 0;
      })
      .slice(0, 3);
  }

  clear() {
    this.bySymbol = {};
    this.positions = [];
    this.working = null;
  }
  constructor() {
    makeAutoObservable(this, {
      positions: observable.ref,
      bySymbol: observable.ref,
    });
  }

  async fetchAndUpdateOrders() {
    api.get('/api/orders').then(res => {
      this.addPositions(res.data);
    });
  }

  removePosition = (n: string) => {
    const id = String(n);
    this.positions = this.positions.filter(p => String(p.id) !== id);
  };

  addPositions = (data: Tapi.Order[]) => {
    this.bySymbol = {};
    console.log('positions', data);
    this.positions = data
      .filter(n => {
        const symbol = symbolStore.resolveSymbol(n.Symbol);
        if (symbol?.symbol === n.Symbol) {
          return true;
        } else {
          captureException('Symbol in position not found', {
            tags: {
              source: 'OrdersStore',
              symbol: JSON.stringify(symbol),
              position: JSON.stringify(n),
            },
          });
          return false;
        }
      })
      .map(n => {
        const order = new Order(n);

        // If the symbol key already exists in the bySymbol object, push the order into the array
        // Otherwise, create a new array for the symbol and add the order into it
        if (this.bySymbol[n.Symbol]) {
          this.bySymbol[n.Symbol].push(order);
        } else {
          this.bySymbol[n.Symbol] = [order];
        }

        return order;
      });
  };
}

export const ordersStore = new OrdersStore();
