import {api, cmsApi, mobileApi, mobileApiV3} from './api';
import {symbolStore} from './stores/SymbolStore';
import {accountStore} from './stores/AccountStore';
import {PaymentSource} from '@/Features/Profile/Deposits/screens/DepositWithBankTransfer/types';
import {
  RecurringPayment,
  RecurringPaymentBill,
} from '@/Features/Profile/Deposits/screens/ManageRecurringPayments/types';
import sortBy from 'lodash/sortBy';
import type {AlertData} from './stores';
import type {
  News,
  Strapi,
  CardPaymentRequest,
  Instrument,
  PaymentResponse,
  IGateToPayCardResponse,
} from './types';

type IBalance = {
  balance: string;
  margin: string;
  profit: string;
  floatingProfit: string;
  swap: string;
  dt: number;
};

export const getNotification = (login: string) =>
  mobileApi.get<News.Notifications[]>(`/user-notifications${login ? '?login=' + login : ''}`);
export const removeNotifications = (notification_ids: number[] | number) =>
  mobileApi.post<News.Notifications[]>('/remove-user-notifications', {
    notification_ids,
  });
export const getBanners = () =>
  cmsApi
    .get<Strapi.Banners['data']>('/banners?populate=*')
    .then(row => sortBy(row.data, ['order', 'id']));

export const getBalanceGraph = () =>
  api.get<IBalance[]>('/api/daily').then(({data}) => {
    return data
      .map(row => ({
        value: Number(row.floatingProfit) || 0,
        date: new Date(row.dt),
      }))
      .filter(n => n.value !== 0);
  });

export const alerts = {
  get: () =>
    mobileApi.get<AlertData[]>('/price-alerts').then(({data}) => {
      const notFound = data.filter(alert => !symbolStore.exists(alert.symbol));
      if (notFound.length > 0) notFound.forEach(alert => alerts.delete(alert.id));

      const filtered = data.filter(alert => symbolStore.exists(alert.symbol));
      return filtered;
    }),
  update: (id: string, param: any) => mobileApi.patch<AlertData[]>('/price-alerts/' + id, param),
  delete: (id: string) => mobileApi.delete<AlertData[]>('/price-alerts/' + id),
};

export const payWithCard = (data: CardPaymentRequest) =>
  mobileApi.post<PaymentResponse>('/payment', data);
export const getSavedCards = () => mobileApi.get<Instrument[]>('/instruments');
export const deleteCard = (id: string) => mobileApi.post('/deleteInstrument', {id});
export const getPDT = () =>
  api.get<{count: number; equity: number}>('/api/pdt').then(({data}) => ({
    count: Number(data.equity) < 25000 ? data.count : -2,
    equity: data.equity,
  }));

export const getBankAccounts = () =>
  mobileApi.get<PaymentSource[]>('/paymentSources').then(({data}) => data);

export const getRecurringPayments = (mt_login?: string | number) =>
  mobileApiV3
    .get<RecurringPayment[]>('/recurringPayments', {params: {mt_login}})
    .then(({data}) => data);

export const toggleRecurringPayment = (mt_login: string, id: number, enabled: boolean) =>
  mobileApiV3.patch<boolean>(`/recurringPayments`, {mt_login, enabled, id}).then(({data}) => data);

export const getRecurringPaymentsBills = (recurring_payment_id: number) =>
  mobileApi
    .get<RecurringPaymentBill[]>('/recurringPayments/bills', {
      params: {mt_login: accountStore?.Login, recurring_payment_id},
    })
    .then(({data}) => data);

export const requestWhishPayment = (payload: {
  amount: string;
  currency: string;
  mt_login: string;
  account_id: number;
}) => mobileApiV3.post<{redirectUrl: string}>('/whish/request', payload).then(({data}) => data);

export const requestGate2PayOtp = (payload: {
  cardId?: string;
  cardExpiryDate?: string;
  amount?: number;
  currency?: string;
}) => mobileApiV3.post('/gatetopay/otp', payload).then(({data}) => data);

export const depositGate2Pay = (payload: {
  cardId?: string;
  cardExpiryDate?: string;
  amount?: number;
  currency?: string;
  mt_login?: string;
  account_id?: number;
  otp: string;
  transactionId: string;
}) => mobileApiV3.post('/gatetopay/deposit', payload).then(({data}) => data);

export const getGate2PayCards: () => Promise<IGateToPayCardResponse> = () =>
  mobileApiV3
    .get<{
      customerCards: {
        id: string;
        cardNumber: string;
      }[];
      isSuccess: Boolean;
      errorCode: string;
      errorMessage: string;
    }>('/gatetopay/cards')
    .then(({data}) => data);
