import {makeAutoObservable, runInAction} from 'mobx';

export class TickStore {
  data: Record<string, Tick> = {};
  constructor() {
    this.onTick = this.onTick.bind(this);
    makeAutoObservable(this);
  }
  onTick(symbol: string, data: PriceTick | PriceStat) {
    runInAction(() => {
      const oldTick = this.data[symbol];
      if (oldTick?.DateTime && data.Datetime <= oldTick.DateTime) return;

      if ('dayHigh' in data) {
        this.data[symbol] = {
          dayHigh: data.dayHigh,
          dayLow: data.dayLow,
          openPrice: data.open,
          closePrice: data.close,
          bid: data.Bid,
          ask: data.Ask,
          ltp: oldTick?.ltp ? oldTick.ltp : data.ltp,
          bidDir: oldTick?.bid ? oldTick.bid > data.Bid : false,
          askDir: oldTick?.ask ? oldTick.ask > data.Ask : false,
          DateTime: data.Datetime,
        };
        return;
      }

      this.data[symbol] = Object.assign(oldTick || {}, {
        bid: data.Bid,
        ask: data.Ask,
        ltp: data.ltp,
        DateTime: data.Datetime,
      });
    });
  }
}

export const tickStore = new TickStore();

export interface Tick {
  dayHigh: number;
  dayLow: number;
  openPrice: number;
  closePrice: number;
  bid: number;
  ask: number;
  ltp: number;
  bidDir: boolean;
  askDir: boolean;
  DateTime: number;
}

interface PriceTick {
  Datetime: number;
  Ask: number;
  Bid: number;
  ltp: number;
}

interface PriceStat {
  Symbol: string;
  Bid: number;
  Ask: number;
  dayHigh: number;
  dayLow: number;
  ltp: number;
  Datetime: number;
  close: number;
  open: number;
}
