import {AuthContent} from './AuthContent';
import {openPopup} from './utils/open-popup.web';
import {appUiStore, mobileApi, useT} from '@/Lib';
import {signInUaepass} from '@/Features/PublicApp/screens/lead-phase/LoginScreen/utils/sign-in-with-uaepass';
import {navigateAndReset} from '@/helpers/navigation';
import {useSignupStore} from '@/Features/PublicApp/SignupStore';
import {logBrazeEvent} from '@/Lib/analytics/braze';
import {useAppLang} from '@amana/ui';
import {
  Auth,
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  TwitterAuthProvider,
} from 'firebase/auth';
import {useState} from 'react';
import {Alert} from 'react-native';

const GoogleAuthProviderInstance = new GoogleAuthProvider();
const FacebookAuthProviderInstance = new FacebookAuthProvider();
const TwitterAuthProviderInstance = new TwitterAuthProvider();
const AppleAuthProviderInstance = new OAuthProvider('apple.com');
const signInSocial = (auth: Auth, provider: any, socialType: string, signup: any) =>
  signInWithPopup(auth, provider)
    .then(result => {
      let credential;
      switch (provider) {
        case GoogleAuthProviderInstance:
          credential = GoogleAuthProvider.credentialFromResult(result);
          break;
        case FacebookAuthProviderInstance:
          credential = FacebookAuthProvider.credentialFromResult(result);
          break;
        case TwitterAuthProviderInstance:
          credential = TwitterAuthProvider.credentialFromResult(result);
          break;
        case AppleAuthProviderInstance:
          credential = OAuthProvider.credentialFromResult(result);
          break;
        default:
          throw new Error('Unsupported provider');
      }

      const token = credential?.idToken || credential?.accessToken;
      return mobileApi
        .post('/auth/signin-social', {
          social: socialType,
          token: token,
        })
        .then(res => {
          appUiStore.processAccesstoken(res.data);
        })
        .catch(e => {
          if (token && e.response.status === 409) {
            signup.Token = token;
            signup.Type = socialType;
            navigateAndReset(
              [
                {
                  name: 'PersonalInformationIntroScreen',
                },
              ],
              0
            );
          }
        });
    })
    .catch((error: any) => {
      Alert.alert(error.message);
    });

export function OAuth() {
  const [gauth] = useState(() => getAuth());
  const lang = useAppLang();
  const t = useT();
  const signupStore = useSignupStore();
  gauth.languageCode = lang[0];

  return (
    <AuthContent
      onPressUAEPass={() =>
        openPopup().then(token =>
          signInUaepass(token, () => null, t.social.signInFailed).catch(console.warn)
        )
      }
      onPressApple={() => {
        signInSocial(gauth, AppleAuthProviderInstance, 'apple', signupStore).catch(console.warn);
        logBrazeEvent('social_signin', {type: 'apple'});
      }}
      onPressGoogle={() => {
        signInSocial(gauth, GoogleAuthProviderInstance, 'google', signupStore).catch(console.warn);
        logBrazeEvent('social_signin', {type: 'google'});
      }}
      onPressFacebook={() => {
        signInSocial(gauth, FacebookAuthProviderInstance, 'facebook', signupStore).catch(
          console.warn
        );
        logBrazeEvent('social_signin', {type: 'facebook'});
      }}
    />
  );
}
