import type {Tapi} from '@/Lib/websocket';
import type {SymbolInfo} from './SymbolInfo';

export class Deal {
  isBuy: boolean;
  SymbolInstance: SymbolInfo;

  public static Action = [
    ['BUY', 'A Buy deal.', 0],
    ['SELL', 'A Sell deal.', 1],
    ['BALANCE', 'A balance operation.', 2],
    ['CREDIT', 'Credit operation.', 3],
    ['CHARGE', 'Additional charges/withdrawals.', 4],
    ['CORRECTION', 'Correcting operations.', 5],
    [
      'BONUS',
      'Bonuses. Operations of this type affect the credit assets of a client (IMTUser::Credit).',
      6,
    ],
    ['COMMISSION', 'Commission.', 7],
    ['COMMISSION_DAILY', 'Daily commission.', 8],
    ['COMMISSION_MONTHLY', 'Monthly commission.', 9],
    ['AGENT_DAILY', 'Daily agent commission.', 10],
    ['AGENT_MONTHLY', 'Daily agent commission.', 11],
    ['INTERESTRATE', 'Accrual of annual interest.', 12],
    [
      'BUY_CANCELED',
      "A canceled Buy deal. the Gateway API can notify the platform about the cancellation of a previously executed deal in the external trading system. In this case the type of the earlier executed Buy trade is replaced with this one. The profit/loss of a trade is cleared. Then the client's position is recalculated and the appropriate profit/loss is added/subtracted as a separate balance operation. Deal cancellation does not change the client's order history. Deal cancellation does not entail changes in client's orders history. A deal of the BUY_CANCELED type is not included into the calculation of the financial state of account and is not taken into account in recalculated positions.",
      13,
    ],
    [
      'SELL_CANCELED',
      "A canceled Sell deal. the Gateway API can notify the platform about the cancellation of a previously executed deal in the external trading system. In this case the type of the earlier executed Buy trade is replaced with this one. The profit/loss of a trade is cleared. Then the client's position is recalculated and the appropriate profit/loss is added/subtracted as a separate balance operation. Deal cancellation does not change the client's order history. Deal cancellation does not entail changes in client's orders history. A deal of the SELL_CANCELED type is not included into the calculation of the financial state of account and is not taken into account in recalculated positions.",
      14,
    ],
    ['DIVIDEND', 'Dividend operations.', 15],
    [
      'DIVIDEND_FRANKED',
      'Franked (non-taxable) dividend operations (tax is paid by a company, not a client).',
      16,
    ],
    ['TAX', 'Charging a tax.', 17],
    [
      'AGENT',
      "Charging an agent commission. Used during an instant commission charge to an agent (each time the agent's client performs a deal).",
      18,
    ],
    [
      'SO_COMPENSATION',
      'An operation connected with the compensation of a negative account after the Stop Out event (IMTConGroup::TRADEFLAGS_SO_COMPENSATION).',
      19,
    ],
    [
      'SO_COMPENSATION_CREDIT',
      'Withdrawing credit funds after a negative balance compensation operation',
      20,
    ],
  ];

  public static Entry = [
    ['IN', 'Enter market', 0],
    ['OUT', 'Enter market', 1],
    ['INOUT', 'Reversal', 2],
    ['OUTBY', 'Close by', 3],
  ];

  public static Reason = [
    ['USER', 'The deal is conducted by a client manually through the client terminal.', 0],
    ['EXPERT', 'The deal is conducted by a client using an Expert Advisor.', 1],
    ['USER', 'The deal is conducted by a dealer through the manager terminal.', 2],
    ['SL', 'The deal is conducted as a result of Stop Loss activation.', 3],
    ['TP', 'The deal is conducted as a result of Take Profit activation.', 4],
    ['SO', 'The deal is conducted when the client reached the Stop-Out level.', 5],
    ['ROLLOVER', 'The deal is conducted when reopening a position for charging swaps.', 6],
    [
      'EXTERNAL_CLIENT',
      'The deal is conducted from an external trading system. As opposed to EXTERNAL_SERVICE, commission is charged in this type of deal.',
      7,
    ],
    ['VMARGIN', 'The deal is conducted to charge the variation margin.', 8],
    ['GATEWAY', 'The deal is conducted by a MetaTrader 5 gateway connected to the platform.', 9],
    [
      'SIGNAL',
      'The deal is conducted as a result of copying a trading signalaccording to the subscription in the client terminal.',
      10,
    ],
    [
      'SETTLEMENT',
      'Forced closing of a position due to a futures/option delivery date coming into effect.',
      11,
    ],
    [
      'TRANSFER',
      'The deal is conducted as a result of relocating a position with a calculated price to a new symbol with the same underlying asset.',
      12,
    ],
    [
      'SYNC',
      'The deal is conducted while synchronizing a trading account with an external system.',
      13,
    ],
    [
      'EXTERNAL_SERVICE',
      'Deal performed from an external trading system for technical reasons (for example, to correct the trade state of a client). Commission is not charged on this type of deals.',
      14,
    ],
    [
      'MIGRATION',
      "The deal is created while importing clients' trading operations from the MetaTrader 4 server.",
      15,
    ],
    [
      'MOBILE',
      'The deal is conducted via the MetaTrader 5 mobile terminal for Android or iPhone.',
      16,
    ],
    ['WEB', 'The deal is conducted via the web terminal.', 17],
    ['SPLIT', 'The deal is conducted as a result of a symbol split.', 18],
  ];

  constructor(deal: Tapi.Deal, symbol: SymbolInfo, tz: number) {
    this.isBuy = `${deal.Action}` === '0';
    this.SymbolInstance = symbol;

    // ------
    this.Deal = Number(deal.Deal);
    this.Login = Number(deal.Login);
    this.PositionID = Number(deal.PositionID);
    this.Order = Number(deal.Order);
    this.Action = Number(deal.Action);
    this.Entry = deal.Entry in Deal.Entry ? Deal.Entry[deal.Entry as any][0] : 0;
    this.Reason = Number(deal.Reason);
    this.Time = new Date((Number(deal.Time) - tz) * 1000);
    this.Symbol = symbol;
    this.Price = Number(deal.Price);
    this.VolumeExt = Number(deal.VolumeExt);
    this.Profit = Number(deal.Profit);
    this.Storage = Number(deal.Storage);
    this.Commission = Number(deal.Commission);
    this.Fee = Number(deal.Fee);
    this.RateProfit = Number(deal.RateProfit);
    this.RateMargin = Number(deal.RateMargin);

    this.Comment = String(deal.Comment);
    this.ProfitRaw = Number(deal.ProfitRaw);
    this.PricePosition = Number(deal.PricePosition);
    this.PriceSL = Number(deal.PriceSL);
    this.PriceTP = Number(deal.PriceTP);
    this.VolumeClosedExt = Number(deal.VolumeClosedExt);
    this.Value = Number(deal.Value);
    this.MarketBid = Number(deal.MarketBid);
    this.MarketAsk = Number(deal.MarketAsk);
    this.MarketLast = Number(deal.MarketLast);
  }
  Deal: number;
  Login: number;
  Order: number;
  Action: number | string;
  Entry: number | string;
  Reason: number | string;
  Time: Date;
  Symbol: SymbolInfo;
  Price: number;
  VolumeExt: number;
  Profit: number;
  Storage: number;
  Commission: number;
  Fee: number;
  RateProfit: number;
  RateMargin: number;
  PositionID: number;
  Comment: string;
  ProfitRaw: number;
  PricePosition: number;
  PriceSL: number;
  PriceTP: number;
  VolumeClosedExt: number;
  Value: number;
  MarketBid: number;
  MarketAsk: number;
  MarketLast: number;

  get Multiplier() {
    return 1;
  }
  get RequiredMargin() {
    return (
      ((this.RateMargin *
        (`${this.SymbolInstance.data.CalcMode}` === '0' ? 1 : this.PricePosition || this.Price) *
        Number(this.Units)) /
        this.SymbolInstance.getLeverage(this.isBuy)) *
      this.Multiplier
    );
  }
  get Units() {
    const u = (Number(this.VolumeExt) / 100000000) * Number(this.SymbolInstance.data.ContractSize);

    return Number(this.Symbol.roundUnits(u));
  }
}
