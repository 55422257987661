import {
  AppStackParamList,
  AuthStackParamList,
  RootStackParamList,
  RootTabParamList,
} from './../../types/index';
import {appUiStore, waitFor} from '@/Lib';
import Image from '@/components/Themed/Image';
import {Keyboard} from 'react-native';
/**
 * Used to navigating without the navigation prop
 * @see https://reactnavigation.org/docs/navigating-without-navigation-prop/
 *
 * You can add other navigation functions that you need and export them
 */
import {CommonActions, StackActions, createNavigationContainerRef} from '@react-navigation/native';
import {when} from 'mobx';

export const navigationRef = createNavigationContainerRef<AppStackParamList>();

navigationRef.addListener('state', () => {
  Image.clearMemoryCache();
  Keyboard.dismiss();
});
// declare global {
//   namespace ReactNavigation {
//     interface RootParamList extends RootStackParamList {}
//   }
// }

export function navigate<
  V extends keyof (RootStackParamList & RootTabParamList & AuthStackParamList),
  T extends RootStackParamList & RootTabParamList & AuthStackParamList
>(name: V, params?: T[V]) {
  if (navigationRef.isReady()) {
    // @ts-ignore @FIXME: type inferal should be fixed
    navigationRef.navigate(name, params);
  } else {
    waitFor(() => navigationRef.isReady(), 50, 2000)
      .then(() => navigate(name, params))
      .catch(() => console.debug('ignored navigation to :' + name));
  }
}

export function push<
  V extends keyof (RootStackParamList & RootTabParamList & AuthStackParamList),
  T extends RootStackParamList & RootTabParamList & AuthStackParamList
>(name: V, params?: T[V]) {
  if (navigationRef.isReady()) {
    navigationRef.dispatch(StackActions.push(name, params));
  } else {
    waitFor(() => navigationRef.isReady(), 50, 2000)
      .then(() => navigationRef.dispatch(StackActions.push(name, params)))
      .catch(() => console.debug('ignored navigation to :' + name));
  }
}

export function navigateAfterAuth(...params: Parameters<typeof navigate>) {
  if (appUiStore.ready) {
    return navigate(...params);
  } else {
    when(
      () => appUiStore.ready,
      () => {
        navigate(...params);
      }
    );
  }
}

export function navigateBack() {
  if (navigationRef.isReady()) {
    navigationRef.dispatch(CommonActions.goBack());
  }
}

export function navigatePop() {
  if (navigationRef.isReady()) {
    navigationRef.dispatch(StackActions.popToTop());
  }
}

export function navigateReplace<V extends keyof RootStackParamList, T extends RootStackParamList>(
  name: V,
  params?: T[V]
) {
  if (navigationRef.isReady()) {
    navigationRef.dispatch(StackActions.replace(name, params));
  }
}
export function navigateToRoot() {
  if (navigationRef.isReady()) {
    navigationRef.dispatch(StackActions.popToTop());
  }
}

export function navigateAndReset(routes?: any[], index = 0) {
  if (navigationRef.isReady()) {
    navigationRef.dispatch(
      CommonActions.reset({
        index: index ?? 0,
        routes: routes ?? [{name: 'Root'}],
      })
    );
  }
}

export function navigateAndSimpleReset(name: string, index = 0, params = {} as any) {
  if (navigationRef.isReady()) {
    navigationRef.dispatch(
      CommonActions.reset({
        index,
        routes: [{name, ...params}],
      })
    );
  }
}
export function pushToRoot(name: string, params: any) {
  if (navigationRef.isReady()) {
    const root = navigationRef.getState()?.routes?.[0].state?.routes?.[0];
    navigationRef.dispatch(
      CommonActions.reset({
        index: root ? 1 : 0,
        routes: root ? [root as any, {name, params}] : [{name, params}],
      })
    );
  }
}

export function changeParams(params: any) {
  if (navigationRef.isReady()) {
    navigationRef.dispatch(CommonActions.setParams(params));
  }
}
