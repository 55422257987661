import {setTrottledTimeout} from '@/Lib';
import {logScreenView} from '@/Lib/analytics';
import {useEffect} from 'react';

export function useScreenTracker(name: string, wait: number = 1000) {
  useEffect(() => {
    if (!name) return;
    return setTrottledTimeout(() => {
      console.log('VIEW:' + name);
      logScreenView(name);
    }, wait);
  }, [name, wait]);

  return null;
}
