import 'react-native-gesture-handler';
import App from './src';
import {AppRegistry, I18nManager} from 'react-native';

global.setImmediate = fn => setTimeout(fn, 0);
I18nManager.allowRTL(true);

const startup = require('./src/setup.web');
startup.startBraze();
startup.startSentry();
startup.startAppsFlyer();
startup.getConversionData();
startup.startAppsFlyer();

AppRegistry.registerComponent('AmanaApp', () => App);
AppRegistry.runApplication('AmanaApp', {
  rootTag: document.getElementById('root'),
});
