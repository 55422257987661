import {toast} from 'react-toastify';
import capitalize from 'lodash/capitalize';

export const toastSuccess = (msg: string) => {
  toast.success(capitalize(msg), {
    autoClose: 5000,
    hideProgressBar: true,
    theme: 'colored',
  });
};

export const toastInfo = (msg: string) => {
  toast.info(capitalize(msg), {
    hideProgressBar: true,
    theme: 'colored',
    autoClose: 5000,
  });
};

export const toastError = (msg: string, props?: any) => {
  toast.error(msg, {
    hideProgressBar: true,
    theme: 'colored',
    autoClose: 5000,
    ...props,
  });
};

export const toastNothideable = (
  msg: string
  // bg: keyof typeof darkColors,
  // color: keyof typeof darkColors
) => {
  toast(capitalize(msg), {
    position: 'top-center',
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  });
};
