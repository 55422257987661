import {OAuth} from './OAuth';
import {LangButton} from '../LangButton';
import {appUiStore, decodeToken, isEmail, mobileApiV3, refreshAppTokens, testId, useT} from '@/Lib';
import {useScreenTracker} from '@/hooks/useScreenTracker';
import {navigate} from '@/helpers/navigation';
import {Header} from '@/Features/PublicApp/components/Header/Header';
import config from '@/config';
import {appSettings} from '@/Lib/storage';
import {MARKETING_URL} from '@/Env/index.web';
import {
  Button,
  DesktopOnly,
  Pressable,
  ScrollView,
  Text,
  TextField,
  useMediaQuery,
  useStyle,
  View,
} from '@amana/ui';
import React, {useCallback, useRef, useState} from 'react';
import {Keyboard, KeyboardAvoidingView, Platform, TextInput} from 'react-native';
import capitalize from 'lodash/capitalize';
import {MMKV} from 'react-native-mmkv';
import {useFocusEffect} from '@react-navigation/native';

export const passStorage = new MMKV({
  id: 'pwd-storage',
  encryptionKey: '£@£e23eDwsdae312123123sdxhunter2',
});

export function Login() {
  const t = useT();
  const S = useStyle();
  const [email, setEmail] = useState<string>(passStorage.getString('LAST_USERNAME') || '');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const passwordRef = useRef<TextInput>(null);
  const [error, setError] = useState<string>();
  const {md} = useMediaQuery();
  useScreenTracker('LoginScreen', 2000);
  const handleSubmit = useCallback(() => {
    if (loading) return;
    if (isEmail(email) === false) return setError('Invalid Email');
    if (password.length < 7) return setError('Invalid Password');
    Keyboard.dismiss();
    setLoading(true);
    passStorage.set('LAST_USERNAME', email);
    return login(email, password, setError).finally(() => {
      setLoading(false);
    });
  }, [email, password]); // eslint-disable-line react-hooks/exhaustive-deps
  const goToPassword = (e?: any) => {
    if (/[a-z0-9._+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(email)) passwordRef.current?.focus();
    else {
      e?.preventDefault();
    }
  };
  const isValid = isEmail(email) && password.length > 6;
  useFocusEffect(
    useCallback(() => {
      const refreshToken = appSettings.getString('refresh_token');
      console.log('REFRESHTOKENLOGIN should refresh token ?', !!refreshToken);
      if (!refreshToken) return;
      const decoded = decodeToken(refreshToken);
      console.log(
        'REFRESHTOKENLOGIN is token expired ?',
        decoded.exp * 1000 + 60 * 1000 < Date.now()
      );

      if (decoded.exp * 1000 + 60 * 1000 < Date.now()) return;

      console.log('REFRESHTOKENLOGIN refreshAppToken');

      refreshAppTokens(refreshToken)
        .then(tokens => {
          if (!tokens) return;
          appUiStore.processAccesstoken({
            access_token: tokens.access_token,
            refresh_token: tokens.refresh_token,
          });

          const refresh_token = tokens.refresh_token;
          const decodedRefresh = decodeToken(refresh_token);

          console.log(
            'REFRESHTOKENLOGIN new tokens',
            new Date(decodedRefresh.exp * 1000).toISOString()
          );
        })
        .catch(() => {
          console.log('REFRESHTOKENLOGIN expired refresh token');
          appSettings.delete('refresh_token');
        });
    }, [])
  );
  return (
    <KeyboardAvoidingView behavior="padding" style={[S.flex]}>
      <ScrollView
        keyboardShouldPersistTaps={'handled'}
        style={[S.flex]}
        contentContainerStyle={[S.flex, S.pad4]}
        scrollEnabled={md}>
        <DesktopOnly
          fallback={
            <Header
              hasBack={false}
              text={t.logIn.login}
              style={{
                marginBottom: 24,
              }}
            />
          }>
          <View style={[S.row, S.wFull, S.justifyEnd, S.pad4]} testID={testId('Login-View-1')}>
            {config.features.isLanguageEnabled ? <LangButton /> : null}
          </View>
        </DesktopOnly>

        <View style={[S.flex, md && S.justify]} testID={testId('Login-View-2')}>
          <DesktopOnly>
            <Pressable
              onPress={() => {
                window.open('https://amana.app/', '_blank');
              }}
              style={[S.align, S.self]}>
              {() => config.horizontal_logo()}
            </Pressable>
          </DesktopOnly>

          <View
            style={[
              md && [
                {
                  width: 400,
                },
                S.self,
                S.mt15,
              ],
              S.gap3,
            ]}
            id="asddsa"
            testID={testId('Login-View-3')}>
            <DesktopOnly>
              <View style={[S.mb4, S.align]}>
                <Text variant="H2B" testID={testId('Login-Text-Title')}>
                  {t.logIn.title}
                </Text>
              </View>
            </DesktopOnly>
            <TextField
              autoFocus={email.length === 0}
              value={email}
              onChangeText={txt => setEmail(txt.replace(/\s/g, ''))}
              onSubmitEditing={goToPassword}
              label={t.logIn.yourEmail}
              textContentType="username"
              autoCapitalize="none"
              autoComplete="email"
              keyboardType="email-address"
              testID={testId('Login-TextField-4')}
            />
            <TextField
              secureTextEntry={!showPassword}
              textContentType="password"
              autoFocus={email.length > 0}
              ref={passwordRef}
              onSubmitEditing={handleSubmit}
              value={password}
              onChangeText={setPassword}
              label={t.logIn.password}
              autoCapitalize="none"
              autoCorrect={false}
              autoComplete="password"
              rightText={showPassword ? t.generatePassword.hide : t.generatePassword.show}
              onRightText={() => setShowPassword(!showPassword)}
              bottomText={error}
              variant={error ? 'error' : undefined}
              testID={testId('Login-TextField-5')}
            />

            <Pressable
              style={[S.selfEnd]}
              onPress={() => navigate('ForgotPasswordScreen')}
              testID={testId('Login-Pressable-6')}>
              <Text variant="Link" color="primary" style={[S.pv0]} testID={testId('Login-Text-7')}>
                {t.logIn.forgotPassword}
              </Text>
            </Pressable>

            <Button
              variant="primary"
              style={[S.mt1]}
              testID={testId('login-screen-login-btn')}
              loading={loading}
              disabled={!isValid || loading}
              onPress={handleSubmit}
              title={t.logIn.login}
            />
          </View>

          {config.features.isSocialLoginEnabled ? <OAuth setLoading={setLoading} /> : null}

          <DesktopOnly>
            <View style={[{marginTop: 72, width: 400}, S.self]}>
              <View
                bg="secondary_5"
                style={[
                  {
                    top: 11,
                    height: 1,
                  },
                ]}
                testID={testId('AuthContent-View-3')}
              />
              <View style={[S.align, S.self, S.ph3]} testID={testId('AuthContent-View-4')}>
                <Text
                  color="secondary_50"
                  variant="SubText"
                  style={[S.text]}
                  testID={testId('AuthContent-Text-5')}>
                  {t.logIn.noAccount}
                </Text>
              </View>
              <Button
                style={[S.mt6]}
                title={t.logIn.signUp}
                variant="primary"
                onPress={() => navigate('SignUpNavigation')}
              />
              {Platform.OS === 'web' && !__DEV__ ? (
                <iframe
                  style={{
                    display: 'none',
                  }}
                  src={`${MARKETING_URL}/webtrader?event=logout`}
                  id="ifr"
                />
              ) : null}
            </View>
          </DesktopOnly>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const login = (
  usr: string,
  pwd: string,
  setError: React.Dispatch<React.SetStateAction<string | undefined>>
) => {
  return mobileApiV3
    .post<{
      access_token: string;
      refresh_token: string;
      access_expires_in: number;
      refresh_expires_in: number;
    }>('/auth/signin', {
      email: usr,
      password: pwd,
      disable_group_check: 1,
    })
    .then(async res => {
      appSettings.set('LOGGED_ONCE', true);
      const row = res.data;
      // --- @TODO: MOVE LOGIC OUT OF SCREEN
      console.log('===>logged in success.. starting app');
      passStorage.set('LAST_USERNAME', usr);
      if (Platform.OS !== 'web') {
        passStorage.set('LAST_PASSWORD', pwd);
      }
      if (!row) throw new Error('could not log in, try again later');
      appUiStore.processAccesstoken(row);
    })
    .catch(e => {
      passStorage.delete('LAST_PASSWORD');
      if (e.response?.status === 412) {
        navigate('InvalidGroupModal');
      } else if (e.response?.data?.errors) {
        e.response?.data?.errors.map((n: any) =>
          setError(capitalize(Object.values(n.constraints || {}).join(', ')))
        );
      } else {
        setError(e.response?.data?.message || e.message || 'Failed to login');
      }
    });
};
