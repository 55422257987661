import {settingsStore} from './SettingsStore';
import {themes} from '@amana/ui';

import {autorun} from 'mobx';

if (typeof document !== 'undefined') {
  autorun(() => {
    const choosenTheme = settingsStore.theme;
    const root = document.documentElement;

    Object.entries(themes[choosenTheme]).forEach(([key, val]) => {
      root.style.setProperty(`--${key}`, val);
    });

    Object.entries(themes[choosenTheme]).forEach(([key, val]) => {
      root.style.setProperty(`--${key}`, val);
    });
  });
}
