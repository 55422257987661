import Logo from './logos/amana';
import {AnimatedSpinner} from '@/config/components/amana/AnimatedSpinner';
import {Icon} from '@amana/ui';
import type {IConfig} from '.';
const config: IConfig = {
  app_name: 'Amana Capital',
  logo: () => <Logo />,
  horizontal_logo: () => <Icon name="LogoLabel" size={201} height={41} />,
  languages: ['en', 'ar'],
  default_language: 'en',
  theme: {},
  features: {
    isLeverageEnabled: true,
    isNotificationsEnabled: true,
    isSignupEnabled: true,
    isLanguageEnabled: true,
    isMTLoginEnabled: true,
    isSocialLoginEnabled: true,
    isDepositEnabled: true,
    isInternalTransferEnabled: true,
    isStatmentEnabled: true,
    isSupportEnabled: true,
    isSettingsEnabled: true,
    isNewsEnabled: true,
    isEventsEnabled: true,
    isContentCardsEnabled: true,
    isDailyUpdatesEnabled: true,
    isRecurringDepositEnabled: true,
    isTermsAndConditionsEnabled: true,
    isAppTourEnabled: true,
    isUAEPassEnabled: false,
    customSignInSpinner: () => <AnimatedSpinner />,
    isWithdrawEnabled: true,
    isStaticIcons: false,
    isTradingViewChartUsed: false,
  },
  navigation: {
    Foryou: {
      isMain: true,
      isShown: true,
      icon: {
        name: 'Home',
        size: 30,
      },
    },
    Watchlist: {
      isMain: false,
      isShown: true,
      icon: {
        name: 'Watchlist',
        size: 30,
      },
    },
    Portfolio: {
      isMain: false,
      isShown: true,
      icon: {
        name: 'Dollar',
        size: 30,
      },
    },
    Plus: {
      isMain: false,
      isShown: true,
      icon: {
        name: 'Plus',
        size: 30,
      },
    },
    Profile: {
      isMain: false,
      isShown: true,
      icon: {
        name: 'Profile',
        size: 30,
      },
    },
  },
};
export default config;
