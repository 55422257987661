import {BACKOFFICE_HTTP, BACKOFFICE_HTTP_V3, CMS_HTTP, HISTORY_API, TAPI_HTTP} from '@/Env';
import {Lambda, makeAutoObservable, onBecomeObserved, runInAction} from 'mobx';
import axios from 'axios';
import type {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';

export * from './common';

axios.defaults.timeout = 30000;
export const api = axios.create({
  baseURL: TAPI_HTTP,
});
export const historyApi = axios.create({
  baseURL: HISTORY_API,
});
export const mobileApi = axios.create({
  baseURL: BACKOFFICE_HTTP,
});

export const testMobileApi = axios.create({
  baseURL: 'http://localhost:3001/api/v1',
});

export const mobileApiV3 = axios.create({
  baseURL: BACKOFFICE_HTTP_V3,
});
export const cmsApi = axios.create({
  baseURL: CMS_HTTP,
});

historyApi.interceptors.request.use(config => {
  if (!config.headers) {
    config.headers = {};
  }
  config.headers.Authorization = `Bearer XXX-DEV`;
  // Object.assign(config.headers, api.defaults.headers);
  return config;
});

type FetchConfig<T, F> = {
  name: string;
  initialState?: T;
  fetchUrl: string;
  throttleMs: number;
  transformData: (data: AxiosResponse<F>) => T;
  enabled?: boolean | (() => boolean);
};

export class ApiFetchStore<T, F> {
  name: string;
  data: T | null = null;
  state: 'idle' | 'loading' | 'error' = 'idle';
  lastFetch = 0;
  throttleMs = 0;
  lastError: AxiosError | null = null;
  private __destroy = [] as Array<Function | Lambda>;
  clear = (keepData = false) => {
    if (!keepData) {
      this.data = null;
    }
    this.state = 'idle';
    this.lastFetch = 0;
    this.throttleMs = 0;
    this.lastError = null;
    this.__destroy.map(fn => fn());
  };

  constructor(private config: FetchConfig<T, F>) {
    this.name = config.name;
    makeAutoObservable(this);
    this.__destroy = [
      onBecomeObserved(this, 'data', () => {
        this.fetchIfNecessary();
      }),
    ];
  }

  refetch = async (params?: AxiosRequestConfig) => {
    this.state = 'loading';
    this.lastError = null;
    return api
      .get<F>(this.config.fetchUrl, params)
      .then(res => {
        runInAction(() => {
          this.data = this.config.transformData(res);
          this.lastFetch = Date.now();
          this.state = 'idle';
        });
        return res;
      })
      .catch(err => {
        this.lastError = err;
        runInAction(() => {
          this.state = 'error';
        });
      });
  };

  fetchIfNecessary = (params?: AxiosRequestConfig) => {
    console.log(' ======= IMPRESSION ===== ' + this.config.name);

    // is disabled
    if (typeof this.config.enabled === 'function' && !this.config.enabled()) return;
    if (this.config.enabled === false) return;

    // it must be ideal
    if (this.state !== 'idle') return;
    // if last fetch is less than 10 minutes ago
    if (this.data !== null && Date.now() - this.lastFetch < this.throttleMs) return;

    this.refetch(params);
  };
}
