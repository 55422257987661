import {testId, useT} from '@/Lib';
import configs from '@/config';
import {IconButton, Text, useMediaQuery, useStyle, View} from '@amana/ui';
import {Platform} from 'react-native';
import {useState} from 'react';

export const AuthContent = ({
  onPressUAEPass,
  onPressGoogle,
  onPressApple,
}: {
  onPressUAEPass?: () => void;
  onPressGoogle: () => void;
  onPressApple?: () => void;
  onPressFacebook?: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const t = useT();
  const S = useStyle();
  const {md} = useMediaQuery();
  return (
    <View style={S.align} testID={testId('AuthContent-View-1')}>
      <View
        style={[
          md
            ? [
                S.mv6,
                {
                  width: 400,
                },
              ]
            : [S.mv6, S.wFull],
          S.relative,
        ]}
        testID={testId('AuthContent-View-2')}>
        <View
          bg="secondary_5"
          style={[
            {
              top: 11,
              height: 1,
            },
          ]}
          testID={testId('AuthContent-View-3')}
        />
        <View style={[S.align, S.self, S.ph3]} testID={testId('AuthContent-View-4')}>
          <Text
            color="secondary_50"
            variant="SubText"
            style={[S.text]}
            testID={testId('AuthContent-Text-5')}>
            {t.general.or}
          </Text>
        </View>
      </View>
      <View style={[S.row, S.justify, S.align, S.gap2]} bg={'transparent'}>
        <IconButton
          bg={'color'}
          style={[S.rad2]}
          onPress={onPressGoogle}
          name="GooglePay"
          testID={testId('AuthContent-Button-6')}
          loadingColor={'primary'}
        />

        {/*<IconButton*/}
        {/*  bg={'color'}*/}
        {/*  style={[S.rad2]}*/}
        {/*  onPress={onPressFacebook}*/}
        {/*  name="Facebook"*/}
        {/*  color="bg"*/}
        {/*  testID={testId('AuthContent-Button-8')}*/}
        {/*/>*/}

        {Platform.OS === 'ios' || Platform.OS === 'web' ? (
          <IconButton
            bg={'color'}
            style={[S.rad2]}
            onPress={onPressApple}
            name="ApplePay"
            color="bg"
            testID={testId('AuthContent-Button-7')}
            loadingColor={'primary'}
          />
        ) : null}
        {configs.features.isUAEPassEnabled ? (
          <IconButton
            loading={loading}
            bg={'color'}
            style={[S.rad2]}
            onPress={() => {
              setLoading(true);
              // @ts-ignore
              onPressUAEPass();
            }}
            color="bg"
            name="UaePass"
            loadingColor={'primary'}
          />
        ) : null}
      </View>
    </View>
  );
};
