import {GeoLocation} from '@/Features/PublicApp/screens/lead-phase/CountryOfResidenceScreen/types';
import {mobileApi} from '@/Lib/api';
import {useQuery} from '@tanstack/react-query';

export function useGeoLocation() {
  const KEY = ['geolocation'];
  const query = useQuery({
    staleTime: Infinity,
    queryKey: KEY,
    queryFn: () => mobileApi.get<GeoLocation>('/geo-location').then(({data}) => data),
  });

  return {query};
}
