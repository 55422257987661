import {logBrazeEvent, setLanguage} from './braze';
import analytics from './ga';
import appsFlyer from './appsflyer';
import {getConfigValue} from '@/firebase/remoteConfig';
import {Platform} from 'react-native';
import type {MobileOfficeAccountInfo} from '@/Lib';
export const setCustomUserAttribute = async (name: string, value: string) => {
  if (__DEV__) return;
  // Braze.setCustomUserAttribute(name, value);
  analytics().setUserProperty(name, value);
};

export const setLang = async (lang: string) => {
  return setLanguage(lang);
};

export const logLogin = (data: MobileOfficeAccountInfo) => {
  //Braze events and user attributes
  analytics().setUserId(data.customer_no);

  if (!__DEV__) {
    //AppsFlyer events and user attributes
    appsFlyer.setCustomerUserId(data.customer_no);
    appsFlyer.logEvent('af_login', {method: 'email'});
    appsFlyer.setUserEmails(
      {emails: [data.email], emailsCryptType: 0},
      _ => {
        null;
      },
      err => {
        console.error('appsFlyer.setUserEmails', err);
      }
    );
  }

  if (Platform.OS === 'ios') {
    import('react-native-tracking-transparency').then(({getTrackingStatus}) => {
      getTrackingStatus().then(status => {
        setCustomUserAttribute('ATT', status);
      });
    });
    // appsFlyer.setAppInviteOneLinkID(cuid);
  }
};

export const logSignUp = async () => {
  if (__DEV__) return;
  analytics().logSignUp({method: 'email'});
  appsFlyer.logEvent('af_complete_registration', {method: 'email'});
};

export const logDeposits = async (value: number, currency: string) => {
  // if (__DEV__) return;
  const remoteConfigGroup = getConfigValue('group');
  const deposit = {
    value,
    currency,
    group: remoteConfigGroup,
  };
  await analytics().logEvent('deposit', deposit);
};

export const logWithdrawals = async (value: number, currency: string) => {
  // if (__DEV__) return;
  const withdraw = {
    value,
    currency,
  };
  await analytics().logEvent('withdrawals', withdraw);
};

export const logPurchase = async (purchase: {
  symbol_name: any;
  symbol_type: any;
  trade_side: any;
  trade_amount: any;
  trade_units: any;
  symbol_leverage: any;
  trade_price: any;
}) => {
  if (__DEV__) return;
  await analytics().logPurchase({
    value: purchase.trade_price,
    currency: 'usd',
    items: [
      {
        item_id: purchase.symbol_name,
        item_name: purchase.symbol_name,
        item_category: purchase.symbol_type,
      },
    ],
  });
};

export const logEvent = async (name: string, state = {} as any) => {
  // if (__DEV__) return;
  await analytics().logEvent(name, state);
  await appsFlyer.logEvent(name, state);
};

export async function logVideoDuration(params: {
  video_provider: string;
  video_action: string;
  video_title: string;
  video_section: string;
  video_percent: undefined | string;
  video_duration: number;
  video_seek: number;
}) {
  if (__DEV__) return new Promise(() => null);
  logBrazeEvent('videotracking', params);
  appsFlyer.logEvent('videotracking', params);
  return analytics().logEvent('videotracking', params);
}

export function logScreenView(title: string) {
  if (__DEV__) return;
  analytics().logScreenView({
    screen_name: title,
    screen_class: title,
  });
}
