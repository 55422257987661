import {TopMoversList} from '@/Features/Foryou/TopMovers/types';
import {mobileApi} from '@/Lib/api';
import {useQuery} from '@tanstack/react-query';

export function useTopMovers({topMoversQuery}: {topMoversQuery: string}) {
  const query = useQuery<TopMoversList>({
    queryKey: ['top-movers', topMoversQuery],
    queryFn: () =>
      mobileApi
        .get(`top-movers?${topMoversQuery}`)
        .then(r => r.data)
        .catch(() => null),
    staleTime: 60 * 5 * 1000,
  });
  return {query};
}
