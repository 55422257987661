import {appUiStore, mobileApi} from '@/Lib';
import {logBrazeEvent} from '@/Lib/analytics/braze';
import {Alert} from 'react-native';

export const signInUaepass = async (token: string, finish: Function, errorMessage: string) => {
  try {
    await mobileApi
      .post('/auth/signin-social', {
        social: 'uaepass',
        token: token,
      })
      .then(res => {
        console.log('res', res);
        appUiStore.processAccesstoken(res.data);
        logBrazeEvent('social_signin', {type: 'uaepass'});
      })
      .catch(e => {
        console.log('error', e);
        Alert.alert(errorMessage);
      });
  } catch (e) {
    console.log('error', e);
    Alert.alert(errorMessage);
  }
  finish();
};
