import {ExchangeRate} from './ExchangeRate';
import {accountStore} from '../AccountStore';
import {appUiStore} from '../AppUi';
import {positionStore} from '../PositionStore';
import {symbolStore} from '../SymbolStore';
import {serverClock} from '../ServerClock';
import {divide, sum} from '../../Math';
import {api} from '../../api';
import {priceEngine} from '../../PriceEngine';
import DeviceEventEmitter from '@/Lib/DeviceEmitter';
import multiply from 'lodash/multiply';
import {makeAutoObservable, runInAction} from 'mobx';
import type {Tapi} from '@/Lib/websocket';

export class Position {
  id = '';
  symbol: string;
  type: 0 | 1 | 2 | 3 | 4 | 5;
  lots: number;
  openPrice!: number;
  createdAt!: number;
  updatedAt!: number;
  profitCurrencyRate = 1;
  marginCurrencyRate = 1;
  sl = 0;
  tp = 0;
  limit = 0;
  digits = 5;
  swap = 0;

  get slUSD() {
    if (!Number(this.sl)) return 0;
    let multiplier = this.isBuy ? -1 : 1;
    return (this.openPrice - this.sl) * this.Units * this.profitCurrencyRate * multiplier;
  }
  get tpUSD() {
    if (!Number(this.tp)) return 0;
    let multiplier = this.isBuy ? -1 : 1;
    return (this.openPrice - this.tp) * this.Units * this.profitCurrencyRate * multiplier;
  }

  get Ric() {
    return this?.Symbol?.data?.Category;
  }

  get Exchange() {
    return this?.Symbol?.data?.Exchange;
  }
  profitRate: number;
  currentPrice: number;
  constructor(props: Tapi.Position) {
    this.id = String(props.Position);
    this.symbol = props.Symbol;
    this.type = Number(props.Action) as 0;
    this.lots = props.VolumeExt
      ? Number(props.VolumeExt) / 100000000
      : Number(props.Volume) / 10000;
    this.openPrice = Number(props.PriceOpen);
    this.currentPrice = Number(props.PriceCurrent);
    this.profitRate = Number(props.RateProfit);
    this.sl = Number(props.PriceSL);
    this.tp = Number(props.PriceTP);
    this.digits = Number(props.Digits || this.Symbol?.Digits);
    this.marginCurrencyRate = Number(props.RateMargin);
    this.profitCurrencyRate = Number(props.RateProfit);
    this.createdAt = Number(props.TimeCreateMsc) - appUiStore.TZ;
    this.updatedAt = Number(props.TimeUpdateMsc) - appUiStore.TZ;
    this.swap = Number(props.Storage || 0) || 0;
    null;
    priceEngine.subscribe(props.Symbol);
    makeAutoObservable(this);
  }
  exchangeRate!: {
    profit: ExchangeRate;
    margin: ExchangeRate;
  };

  setLimit = (n: string | number | null) => {
    const limit = Number(n);
    if (limit > 0) {
      this.limit = limit;
      return;
    }

    //--- reset
    if (n === null && this.Price) {
      if (this.limit === 0) {
        this.limit = this.Price;
      }

      return;
    }

    //--- otherwise.. reset
    this.limit = 0;
  };

  working = false; // loading spinner while closing

  async editRequest(payload: any) {
    return api.put(`/api/positions/${this.id}`, payload).catch(e => {
      DeviceEventEmitter.emit('navigate', [
        'OrderFailed',
        {
          msg: String(e.response?.data?.error || e.response?.data?.message || 'Error occured!'),
        },
      ]);
    });
  }

  close() {
    if (this.Symbol?.isEgyptStock && !this.Symbol.canCloseEgyptStock) {
      DeviceEventEmitter.emit('navigate', ['BlockedEgyptPosition']);
    } else {
      DeviceEventEmitter.emit('navigate', ['ClosePosition', {symbol: this.Symbol.symbol}]);
    }
  }

  async closeRequest() {
    return api
      .delete(`/api/positions/${this.id}`)
      .then(() => {
        runInAction(() => {
          positionStore.removePosition(this.id);
        });
      })
      .catch(e => {
        DeviceEventEmitter.emit('navigate', [
          'OrderFailed',
          {
            msg: String(e.response?.data?.error || e.response?.data?.message || 'Error occured!'),
          },
        ]);
        throw new Error(
          String(e.response?.data?.error || e.response?.data?.message || 'Error occured!')
        );
      });
  }

  get StopsLevel() {
    return (
      Number(this.Units * Number(this.Symbol.data.Point)) * Number(this.Symbol.data.StopsLevel)
    );
  }

  get StopsLevelInUSD() {
    return Number((this.StopsLevel * this.profitCurrencyRate).toFixed(2));
  }

  get SLstop() {
    return this.isBuy ? this.StopsLevelInUSD - this.Profit : this.StopsLevelInUSD + this.Profit;
  }

  get TPstop() {
    return !this.isBuy ? this.StopsLevelInUSD - this.Profit : this.StopsLevelInUSD + this.Profit;
  }

  get Type() {
    if (this.limit) {
      let sym = this.Symbol;
      const isBuy = this.type % 2 === 0;

      if (isBuy && sym.tick?.ask) {
        let price = sym.tick?.ask;
        return price > this.limit ? 2 : 4;
      } else if (!isBuy && sym.tick?.bid) {
        let price = sym.tick?.bid;
        return price > this.limit ? 5 : 3;
      }
    }
    return this.type;
  }

  static TYPES = ['BUY', 'SELL', 'BUY LIMIT', 'SELL LIMIT', 'BUY STOP', 'SELL STOP'];
  get TypeName() {
    return Position.TYPES[this.Type];
  }

  // ---- used in protection
  get StopLevel() {
    return Number(this.Symbol?.data.StopsLevel) * Number(this.PriceStep);
  }

  // ---- used in protection
  get PriceStep() {
    return Number(Math.pow(0.1, this.digits).toFixed(this.digits));
  }

  get isBuy() {
    return this.type % 2 === 0;
  }

  get RequiredMargin() {
    const marginRate =
      Number(
        this.isBuy ? this.Symbol?.data.MarginInitialBuy : this.Symbol?.data.MarginInitialSell
      ) || 1;
    const leverage = Number(accountStore.Leverage);
    const mode = Number(this.Symbol?.data.CalcMode) || 0;

    // --- FOREX
    if (mode === 0) {
      return divide(this.Units, leverage) * this.marginCurrencyRate * marginRate;
    }
    // --- FOREX No Leverage
    if (mode === 5) {
      return this.Units * this.marginCurrencyRate * marginRate;
    }
    // --- CFD
    if (mode === 2) {
      return this.Units * this.openPrice * this.marginCurrencyRate * marginRate;
    }
    // --- CFD Leverage
    if (mode === 4) {
      return ((this.Units * this.openPrice) / leverage) * this.marginCurrencyRate * marginRate;
    }
    // --- CFD Leverage
    if (mode === 3) {
      console.error('CFD INDEX MODE IS NOT SUPPORTED for:' + this.symbol);
      return (
        ((this.Units * this.openPrice * Number(this.Symbol.data.TickValue)) /
          Number(this.Symbol.data.TickSize)) *
        this.marginCurrencyRate *
        marginRate
      );
    }

    // ---- Exchange
    if (mode === 32) {
      return this.Units * this.openPrice * this.marginCurrencyRate * marginRate;
    }

    // ---- FUTURES
    console.error('Trade MODE IS NOT SUPPORTED for:' + this.symbol);

    return 0;
  }

  get Exposure() {
    return (this.RequiredMargin * this.Symbol.getLeverage(this.isBuy)).toFixed(2);
  }

  get Price() {
    const sym = this.Symbol;
    if (!sym) {
      return 0;
    }

    return this.isBuy ? sym.Ask : sym.Bid;
  }

  // get CounterPrice() {
  //   const sym = this.Symbol;
  //   if (!sym) {
  //     return 0;
  //   }
  //   const counterPrice = sym.isStock ? sym.tick?.ltp : this.isBuy ? sym.tick?.ask : sym.tick?.bid;
  //   return counterPrice || 0;
  // }

  get CounterPrice() {
    const sym = this.Symbol;
    if (!sym || !sym.tick?.ask || !sym.tick?.bid) {
      return 0;
    }

    if (sym?.isStock) return sym.tick?.ltp;

    return this.isBuy
      ? sym.ShouldDelay
        ? sym.delayedBid
        : sym.tick?.bid
      : sym.ShouldDelay
      ? sym.delayedAsk
      : sym.tick?.ask;
  }

  get Symbol() {
    return symbolStore.getSymbolSync(this.symbol)!;
  }

  getMovementRequired(usd: number) {
    if (!usd) {
      return 0;
    }
    const perlot = usd / this.Units; // in account
    const move = perlot * (1 / this.profitCurrencyRate);
    return Number(move.toFixed(Number(this.digits) || 5));
  }

  get Units() {
    const u = multiply(this.lots, Number(this.Symbol?.data.ContractSize));

    return Number(this.Symbol.roundUnits(u));
  }

  get EquityPerc() {
    const price = this.RequiredMargin;
    const eq = (price / Number(accountStore.Equity || 0)) * 100;
    return eq ? eq.toFixed(2) : 0;
  }

  get Value() {
    return sum(this.RequiredMargin, this.Profit);
  }
  get ProfitMultiplier() {
    return this.type % 2 === 0 ? 1 : -1;
  }
  get ProfitInPositionCurrency() {
    let s = this.Symbol;
    if (!s) {
      return 0;
    }
    let price =
      (s.isStock ? s.tick?.ltp : this.type % 2 === 0 ? s.tick?.bid : s.tick?.ask) ||
      this.currentPrice;

    if (!price) return 0;
    return Number((price - this.openPrice) * this.Units) * this.ProfitMultiplier;
  }
  get Profit() {
    let val: number;

    if (String(this.Symbol.data?.CurrencyProfit) !== 'USD') {
      if (!this.exchangeRate) {
        this.exchangeRate = {
          profit: new ExchangeRate(this.Symbol, 'Profit'),
          margin: new ExchangeRate(this.Symbol, 'Margin'),
        };
      }
      val =
        this.exchangeRate.profit.fromCurrencyToAccount * this.ProfitInPositionCurrency + this.swap;
    } else {
      val = this.ProfitInPositionCurrency + this.swap;
    }
    return (
      val ||
      Number((this.currentPrice - this.openPrice) * this.Units) *
        this.ProfitMultiplier *
        this.profitRate
    );
  }

  // get StopDistance() {
  //   return ((this.CounterPrice - this.sl) * this.profitCurrencyRate).toFixed(2);
  // }
  // get ProfitDistance() {
  //   return ((this.CounterPrice - this.tp) * this.profitCurrencyRate).toFixed(2);
  // }
  // get StopLevelProfit() {
  //   let multiplier = this.type % 2 === 0 ? 1 : -1;
  //   return ((this.sl - this.openPrice) * this.Units * this.profitCurrencyRate * multiplier).toFixed(
  //     2
  //   );
  // }
  // get TakeProfitLevelProfit() {
  //   let multiplier = this.type % 2 === 0 ? 1 : -1;
  //   return ((this.tp - this.openPrice) * this.Units * this.profitCurrencyRate * multiplier).toFixed(
  //     2
  //   );
  // }
}
serverClock;
