import {appSettings, storage} from './../storage';
import {accountStore} from './AccountStore';
import {positionStore} from './PositionStore';
import {ordersStore} from './OrderStore';
import {symbolStore} from './SymbolStore';
import {api, mobileApi, historyApi, mobileApiV3} from '../api';
import {settingsStore} from '@/Lib/stores/SettingsStore';
import {TradingModes} from '@/components/Charts/Chart/helper';
import {changeUser} from '@/Lib/analytics/braze';
import {appVersion, buildNumber} from '@/Env';
import {makeAutoObservable, runInAction} from 'mobx';
import jwt_decode from 'jwt-decode';
import {captureException, setTag} from '@sentry/react-native';
import {Platform} from 'react-native';
import {AppEventsLogger} from 'react-native-fbsdk-next';
import type {AxiosRequestConfig} from 'axios';
import type {News, Strapi} from '../types';
export class AppUiStore {
  ready = false;
  showSearch = false;
  keyword = '';
  accountId = '';
  tradingMode: TradingModes | null = null;
  token: string | null = '';
  jwt: JwtToken | null = null;
  banners = [] as Strapi.Banners['data'];
  howtos = [] as Strapi.HowTos['data'];
  NewsResponse = [] as News.NewsResponse[];
  notifications = [] as News.Notifications[];
  mandatoryUpdate: boolean | null = null;
  location: string | null = '/';
  layout = {x: 0, y: 0, width: 0, height: 0};
  TZ = 7200;
  accountSwitcher = false;
  lockAccountSwitcher = false;
  currentRoute = '';
  statusModal: null | {title?: string; msg?: string} = null;

  toggleAccountSwitcher = (val: boolean) => {
    this.lockAccountSwitcher = true;
    this.accountSwitcher = val;
  };

  setAppDimensions = (layout: {width: number; height: number}) => {
    for (let key in layout) {
      if (key in this.layout) {
        // @ts-expect-error
        this.layout[key] = Number(layout[key]);
      }
    }
  };

  get lastNotificationKey() {
    return 'lastNotification.' + (this.accountId || '');
  }

  setTradingMode(show: TradingModes | null) {
    this.tradingMode = show;
  }

  clear = () => {
    console.debug('resetting app ui');
    runInAction(() => {
      this.ready = false;
      this.showSearch = false;
      this.keyword = '';
      this.accountId = '';
      this.token = '';
      this.jwt = null;
      this.banners = [] as Strapi.Banners['data'];
      this.howtos = [] as Strapi.HowTos['data'];
      this.NewsResponse = [];
      this.notifications = [];
      this.accountSwitcher = false;
    });
  };

  async processAccesstoken(
    tokens: {access_token: string; refresh_token?: string},
    dontSave = false
  ) {
    if (this.token === tokens.access_token) {
      console.error("DUPLICATE TOKENS, DON'T PROCESS");
    }

    tokens.refresh_token && appSettings.set('refresh_token', tokens.refresh_token);
    if (!dontSave) {
      tokens.access_token && storage.set('PREVIOUS_SESSION_TOKEN', tokens.access_token);
    }
    const token = tokens.access_token;
    const jwt = jwt_decode(token) as JwtToken;

    // verify expiry of token
    if (jwt.exp * 1000 < Date.now()) {
      throw new Error('Token expired');
    }

    runInAction(() => {
      this.token = token;
      this.jwt = jwt;
      this.accountId = String(jwt.active || jwt.mt?.length ? jwt.mt[0]?.login : 0);
    });

    try {
      await symbolStore.init(this.ActiveAccount?.group || 'default');
    } catch (e) {
      captureException(e);
    }
    // change user in braze and request PUSH permissions
    setTag('CUID', String(jwt.sub));
    setTag('MT', String(jwt.active));
    changeUser(jwt.sub, token);
    Platform.OS !== 'web' && AppEventsLogger.setUserID(jwt.sub);
  }

  constructor() {
    makeAutoObservable(this);
  }
  fetchAndUpdateMTData() {
    return api.get('/api/mtdata').then(res => {
      const TZ = Number(res.headers['x-tz-offset']);
      if (TZ) {
        runInAction(() => {
          this.TZ = TZ;
        });
      }
      accountStore.update({...res.data.user, ...res.data.metrics});
      positionStore.addPositions(res.data.positions);
      ordersStore.addPositions(res.data.orders);
      return res.data;
    });
  }

  get ActiveAccount() {
    const jwt = this.jwt;
    if (!jwt || !jwt.mt?.length) return null;
    const account = jwt.mt.find(n => String(n.login) === String(this.accountId));
    if (!account) {
      runInAction(() => {
        this.accountId = String(jwt.mt[0].login);
      });
      return jwt.mt[0];
    }

    return account;
  }

  setCurrentRoute(route?: string) {
    if (!route) return;
    this.currentRoute = route;
  }

  setStatusModal(data: typeof this.statusModal) {
    this.statusModal = data;
  }
}

export const appUiStore = new AppUiStore();

export function injectLanguageAndToken(config: AxiosRequestConfig): AxiosRequestConfig {
  console.log('GETTING: ', config.url);
  if (config?.headers) {
    // @ts-ignore
    config.headers['Accept-Language'] = settingsStore.lang;
  } else {
    config.headers = {
      'Accept-Language': settingsStore.lang,
    };
  }

  if (appUiStore.token) {
    config.headers.Authorization = `Bearer ${appUiStore.token}`;
  } else {
    delete config.headers.Authorization;
  }

  if (appUiStore.accountId) {
    config.headers['x-login'] = `${appUiStore.accountId}`;
  } else {
    delete config.headers['x-login'];
  }

  config.headers['x-platform'] = Platform.OS;
  if (Platform.Version) config.headers['x-platform-version'] = Platform.Version;
  config.headers['x-app-version'] = appVersion;
  config.headers['x-version'] = buildNumber;

  return config;
}
api.interceptors.request.use(injectLanguageAndToken);
mobileApi.interceptors.request.use(injectLanguageAndToken);
mobileApiV3.interceptors.request.use(injectLanguageAndToken);
historyApi.interceptors.request.use(injectLanguageAndToken);

// Generated by https://quicktype.io

export interface JwtToken {
  sub: string;
  active: string;
  exp: number;
  iat: number;
  id: number;
  mt: {
    account_id: number;
    dw_id: string;
    group: string;
    login: string;
  }[];
  role: number;
  type: string;
}
