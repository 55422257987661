import {
  QueryClient,
  QueryClientProvider,
  onlineManager,
  focusManager,
  MutationKey,
  QueryCache,
} from '@tanstack/react-query';
import {ReactNode, useEffect} from 'react';
import NetInfo from '@react-native-community/netinfo';
import {AppState, AppStateStatus} from 'react-native';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // suspense: true,
      staleTime: 1000 * 60 * 5, // 5minutes
      // @ts-ignore
      useErrorBoundary: () => false,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      // only show error toasts if we already have data in the cache which indicates a failed background update
      if (query.state.data !== undefined) {
        console.error('query error', error, query);
      }
    },
  }),
});

export const getQueryCache = (queryKey: MutationKey) =>
  queryClient.getQueryCache().find(queryKey)?.state;
export const setQueryCache = queryClient.setQueryData.bind(queryClient);

export function QueryProvider({children}: {children: ReactNode}) {
  useEffect(() => {
    function onAppStateChange(status: AppStateStatus) {
      focusManager.setFocused(status === 'active');
    }

    const subscription = AppState.addEventListener('change', onAppStateChange);

    return () => subscription.remove();
  }, []);
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}

onlineManager.setEventListener(setOnline => {
  return NetInfo.addEventListener(state => {
    setOnline(!!state.isConnected);
  });
});
