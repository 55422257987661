import {testId} from '@/Lib';
import {useAppLang} from '@/hooks/useLang';
import config from '@/config';
import {LanguageCodesNames} from '@/Lib/const';
import {Pressable, Text, useStyle} from '@amana/ui';
import {memo} from 'react';
export const LangButton = memo(() => {
  const S = useStyle();
  const [lang, , toggleLang] = useAppLang();

  if (config.languages.length !== 2) return null;

  return (
    <Pressable
      style={[S.ph2, S.pv1, S.rad1]}
      bg="secondary_10"
      onPress={() => toggleLang()}
      testID={testId('LangButton-Pressable-1')}>
      <Text variant="SubText" testID={testId('LangButton-Text-2')}>
        {lang === config.languages[0]
          ? LanguageCodesNames[config.languages[1]]
          : LanguageCodesNames[config.languages[0]]}
      </Text>
    </Pressable>
  );
});
