import {DateTime} from 'luxon';
import {makeAutoObservable, runInAction, onBecomeObserved, onBecomeUnobserved} from 'mobx';
class Clock {
  tz = +2;
  serverDate: DateTime;
  localOffset = new Date().getTimezoneOffset();
  __interval: any;
  constructor() {
    this.serverDate = DateTime.local().setZone('UTC+2').startOf('day');
    makeAutoObservable(this);
    onBecomeObserved(this, 'serverDate', () => this.start());
    onBecomeUnobserved(this, 'serverDate', () => this.stop());
  }

  start() {
    this.__interval = setInterval(() => {
      runInAction(() => {
        this.serverDate = DateTime.local().setZone('UTC+2').startOf('day');
      });
    }, 10000);
  }
  stop() {
    clearInterval(this.__interval);
  }
}

export const serverClock = new Clock();
