import {Country} from '@/Features/PublicApp/screens/lead-phase/CountryOfResidenceScreen/types';
import {mobileApi} from '@/Lib/api';
import {useQuery, UseQueryResult} from '@tanstack/react-query';

export function useCountries() {
  const KEY = ['countries'];
  const query: UseQueryResult<Country[]> = useQuery({
    staleTime: Infinity,
    queryKey: KEY,
    queryFn: () =>
      mobileApi
        .get<Country[]>('/countries')
        // .get<Country[]>('/syntellicore/countries')
        .then(({data}) => data),
  });

  return {query};
}
