import {ordersStore, positionStore} from './stores';
import {tickStore} from './Prices';
import DeviceEventEmitter from './DeviceEmitter';
import {inAppNotificationsStore} from './stores/InAppNotificationsStore';
import {appUiStore, isJsonString} from '@/Lib';
import {isProduction, PRICE_WS} from '@/Env';
import {navigate as go} from '@/helpers/navigation';
import debounce from 'lodash/debounce';
import {fetch} from '@react-native-community/netinfo';
import {encode, decode} from 'msgpack-lite';

const navigate = debounce(go, 500);
type Log = (...data: any[]) => void;
const log: Log = () => {}; //log;console.log.bind(console, 'PE:'); //
export class PriceEngine {
  socket?: WebSocket & {token?: string};
  subscriptions = new Map<string, boolean>();
  pingInterval: NodeJS.Timeout | undefined; // ho often we send ping;
  pongTimeout: NodeJS.Timeout | undefined; // how long we wait for pong;
  reconnectTimeout: NodeJS.Timeout | undefined; // how long we wait for pong;
  token = '';
  pingMsg = encode({type: 'ping'});

  dispose() {
    console.log('setup websocket dispose');
    this.resetSocket();
    this.subscriptions.clear();
    clearInterval(this.pingInterval);
    clearTimeout(this.pongTimeout);
    clearTimeout(this.reconnectTimeout);
    this.token = '';
  }
  resetSocket = () => {
    if (!this.socket) return null;
    clearInterval(this.pingInterval);
    clearTimeout(this.pongTimeout);
    clearTimeout(this.reconnectTimeout);
    if (this.socket) {
      this.socket.onclose = null;
      this.socket.onopen = null;
      this.socket.onmessage = null;
      this.socket.onerror = null;
      this.socket.close();
      this.socket = undefined;
    }
  };

  setup = async (token: string) => {
    // log caller function
    const err = new Error();
    let stackIndex = err.stack?.lastIndexOf('.setup');
    const stack = err.stack?.slice(stackIndex).split('\n').slice(0, 2).join('\n');
    log('CALLED VIA======>', stack || err.stack);
    if (!token) {
      log('PE: no token to connect with');
      return null;
    }

    if (this.token === token) {
      if (this.socket?.readyState === WebSocket.OPEN)
        return console.warn('PE: you are calling setup too many times.. ignoring');
    }

    this.token = token;
    clearTimeout(this.reconnectTimeout);
    const {isInternetReachable} = await fetch();

    if (isInternetReachable === false) {
      log("PE: can't connect to internet.");
      console.log('here ?');
      return this.autoreconnect();
    }

    log('PE: connecting ');
    const socket = new WebSocket(PRICE_WS);
    socket.binaryType = 'arraybuffer';
    socket.onopen = this.onOpen.bind(this);
    socket.onmessage = this.onmessage.bind(this);
    socket.onclose = this.reconnect.bind(this);
    socket.onerror = this.onError.bind(this);
    this.socket = socket;
  };

  autoreconnect = () => {
    if (!this.reconnectTimeout) {
      this.reconnectTimeout = setTimeout(() => this.reconnect(), 3000);
    }
  };

  reconnect() {
    const {token} = this;
    if (token) {
      this.resetSocket();
      this.setup(token);
    }
  }

  sendPing = () => {
    try {
      this.socket && this.trySend(this.socket, this.pingMsg);
    } catch (e) {
      console.error('PE: error sending ping', e);
    }
  };

  onOpen = () => {
    log('PE: connected');
    if (!this.socket || !this.token)
      return log('PE: connected with no socket or no token !!', this.token);

    let n = encode({
      type: 'login',
      payload: [this.token, isProduction],
    });
    this.trySend(this.socket, n);
    clearInterval(this.pingInterval);
    this.pingInterval = setInterval(this.sendPing, 10000);
    log('PE: sent login command');
  };

  trySend(socket: PriceEngine['socket'], msg: string | ArrayBuffer | ArrayBufferView | Blob) {
    try {
      socket?.send(msg);
    } catch (e) {
      console.error('PE: error sending', e);
    }
  }

  onError(e: Event) {
    // captureException(e, {tags: {source: 'priceEngine'}});
    log('PE ERROR: ', e);
  }

  send = async (type: string, payload: unknown[]) => {
    if (!this.socket) {
      if (this.token && type === 'subscribe') {
        this.setup(this.token);
      } else {
        return console.log('wont send:' + type + payload);
      }
    }

    // const isConnected = this.socket?.readyState !== WebSocket.OPEN && this.ready;

    if (this.isConnected()) {
      let n = encode({
        type,
        payload,
      });
      this.trySend(this.socket, n);
      log("PE: command: '", type, payload);
    } else {
      log("PE: ERROR delayed command: '", type, payload);
    }
  };

  unsubscribe(symbol: string) {
    if (this?.subscriptions.has(symbol) && !positionStore.bySymbol[symbol]) {
      const removed = this.subscriptions.delete(symbol);
      removed && this.send('unsubscribe', [symbol]);
    }
  }

  subscribe = (name: string) => {
    this.subscriptions.set(name, true);
    this.send('subscribe', [name]);
  };

  _handleMTEvents = (payload: any) => {
    const target = payload.Position || payload.PositionID ? 0 : 1;
    if ('Deal' in payload && 'Profit' in payload) {
      // --- on profitable trade
      appUiStore.fetchAndUpdateMTData();
      return true;
    }

    if ('Hook' in payload) {
      // --- DELETED A POSITION
      if (payload.Hook === 'DEL' && payload.Position) {
        DeviceEventEmitter.emit('navigate', [
          'OrderSuccess',
          {
            msg: 'MT_RET_REQUEST_ACCEPTED',
            target,
          },
        ]);
        return true;
      }

      if (payload.State) {
        const n = TRADE_RESPONSE_CODES[payload.State];
        if (n && n[1] !== null) {
          navigate(n[1] ? 'OrderSuccess' : 'OrderFailed', {
            msg: (n[1] ? 'MT_RET_REQUEST_ACCEPTED' : String(n[0])) || undefined,
            target,
          });
          return true;
        }
      }

      if (payload.State === 5) {
        DeviceEventEmitter.emit('navigate', [
          'OrderFailed',
          {
            msg: 'MT_RET_REQUEST_REJECT',
            target,
          },
        ]);
        return true;
      }
      if ('Position' in payload && payload.Hook === 'UP') {
        DeviceEventEmitter.emit('navigate', [
          'OrderSuccess',
          {
            msg: 'MT_RET_REQUEST_ACCEPTED',
            target,
          },
        ]);

        positionStore.fetchAndUpdatePositions();
        return true;
      }
      if (payload.Hook === 'DEL') {
        if ('Position' in payload) {
          positionStore.removePosition(payload.PositionID);
        }
        if ('PositionID' in payload) {
          positionStore.removePosition(payload.PositionID);
        }
        if ('Order' in payload) {
          ordersStore.removePosition(payload.Order);
        }

        return true;
      }

      // ---- LIMIT ORDER PLACED
      if (payload.Hook === 'UP' && payload.State === 1 && payload.PositionID === 0) {
        DeviceEventEmitter.emit('navigate', [
          'OrderSuccess',
          {
            msg: 'MT_RET_REQUEST_ACCEPTED',
            target,
          },
        ]);
      }
      appUiStore.fetchAndUpdateMTData();
      return true;
    }

    return false;
  };
  onmessage = async ({data}: any) => {
    try {
      if (data instanceof ArrayBuffer) {
        // binary frame
        var raw_binary_data = new Uint8Array(data);
        var message = decode(raw_binary_data);
        if (typeof message === 'object' && 'Ask' in message) {
          const t = message;
          return tickStore.onTick(t.Symbol, {
            Symbol: t.Symbol,
            Bid: t.Bid,
            Ask: t.Ask,
            dayHigh: t.dayHigh,
            dayLow: t.dayLow,
            ltp: t.ltp,
            Datetime: t.Datetime,
            close: t.close,
            open: t.open,
          });
        }
        if (Array.isArray(message)) {
          // @DEPRICATED: graph data now come as pngs
          // if (message[0] === 'graph') {
          //   const [, name, rawTicks] = message;
          //   const ticks = JSON.parse(`[${rawTicks}]`) as number[];
          //   lastNbids.set(name, ticks);
          //   return;
          // }

          // -- a market price
          // if (Number(1) === 1) return;
          const [symbol, tsms, bid, ask, last] = message;
          tickStore?.onTick(symbol, {
            Datetime: tsms,
            Ask: ask,
            Bid: bid,
            ltp: last,
          });
          return;
        }
        let handled = false;
        if ('Hook' in message || 'Deal' in message || 'Profit' in message) {
          handled = this._handleMTEvents(message);
        }
        handled || console.warn('UNKNOWN', message);
      } else {
        if (isJsonString(data)) {
          const d = JSON.parse(data);
          if (d?.type === 'push_notification') {
            inAppNotificationsStore.handleWebsocketNotification(d);
            return;
          }
        }
        switch (String(data)) {
          case 'pong':
            log('pong');
            break;
          case '200':
            log('PriceEngine is ready');
            Array.from(this.subscriptions.keys()).map(n => this.subscribe(n));
            break;
          case '403':
            log(data);
            // this.onOpen(); // resend login command
            break;
          default:
            if (/^unsubscribe/.test(data)) {
              log("PE: server unsubscribed us from '", data);
              return;
            }
            console.warn('UNKOWN MSG', data);
            break;
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  isConnected() {
    return this.socket?.readyState === WebSocket.OPEN;
  }
}

export const TRADE_RESPONSE_CODES = [
  ['MT_RET_REQUEST_INWAY', null],
  ['MT_RET_REQUEST_ACCEPTED', null],
  ['MT_RET_REQUEST_PROCESS', null],
  ['MT_RET_REQUEST_REQUOTE', null],
  ['MT_RET_REQUEST_PRICES', null],
  ['MT_RET_REQUEST_REJECT', false],
  ['MT_RET_REQUEST_CANCEL', false],
  ['MT_RET_REQUEST_PLACED', null],
  ['MT_RET_REQUEST_DONE', true],
  ['MT_RET_REQUEST_DONE_PARTIAL', true],
  ['MT_RET_REQUEST_ERROR', false],
  ['MT_RET_REQUEST_TIMEOUT', false],
  ['MT_RET_REQUEST_INVALID', false],
  ['MT_RET_REQUEST_INVALID_VOLUME', false],
  ['MT_RET_REQUEST_INVALID_PRICE', false],
  ['MT_RET_REQUEST_INVALID_STOPS', false],
  ['MT_RET_REQUEST_TRADE_DISABLED', false],
  ['MT_RET_REQUEST_MARKET_CLOSED', false],
  ['MT_RET_REQUEST_NO_MONEY', false],
  ['MT_RET_REQUEST_PRICE_CHANGED', false],
  ['MT_RET_REQUEST_PRICE_OFF', false],
  ['MT_RET_REQUEST_INVALID_EXP', false],
  ['MT_RET_REQUEST_ORDER_CHANGED', true],
  ['MT_RET_REQUEST_TOO_MANY', false],
  ['MT_RET_REQUEST_NO_CHANGES', null],
  ['MT_RET_REQUEST_AT_DISABLED_SERVER', false],
  ['MT_RET_REQUEST_AT_DISABLED_CLIENT', false],
  ['MT_RET_REQUEST_LOCKED', false],
  ['MT_RET_REQUEST_FROZEN', false],
  ['MT_RET_REQUEST_INVALID_FILL', false],
  ['MT_RET_REQUEST_CONNECTION', false],
  ['MT_RET_REQUEST_ONLY_REAL', false],
  ['MT_RET_REQUEST_LIMIT_ORDERS', false],
  ['MT_RET_REQUEST_LIMIT_VOLUME', false],
  ['MT_RET_REQUEST_INVALID_ORDER', false],
  ['MT_RET_REQUEST_POSITION_CLOSED', false],
  ['MT_RET_REQUEST_EXECUTION_SKIPPED', false],
  ['MT_RET_REQUEST_INVALID_CLOSE_VOLUME', false],
  ['MT_RET_REQUEST_CLOSE_ORDER_EXIST', false],
  ['MT_RET_REQUEST_LIMIT_POSITIONS', false],
  ['MT_RET_REQUEST_REJECT_CANCEL', false],
  ['MT_RET_REQUEST_LONG_ONLY', false],
  ['MT_RET_REQUEST_SHORT_ONLY', false],
  ['MT_RET_REQUEST_CLOSE_ONLY', false],
  ['MT_RET_REQUEST_PROHIBITED_BY_FIFO', false],
  ['MT_RET_REQUEST_HEDGE_PROHIBITED', false],
];

export const priceEngine = new PriceEngine();
