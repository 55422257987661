import {Svg, Defs, ClipPath, Path, LinearGradient, Stop, G} from 'react-native-svg';

const Logo = (props: {width: number}) => {
  return (
    <Svg viewBox="10 55 115 40" width={props.width} height={props.width / 3}>
      <Defs>
        <ClipPath id="clippath">
          <Path fill="none" d="M0 0H261.36V153.36H0z" />
        </ClipPath>
        <ClipPath id="clippath-4">
          <Path
            fill="none"
            d="M19.25 71.99c4.38.35 8.45 1.98 11.81 4.53 2.34.59 4.62 1.56 6.73 2.92.86-4.75-.78-9.81-4.74-13.13-.87-.73-1.8-1.32-2.77-1.8a13.87 13.87 0 00-11.02 7.47"
          />
        </ClipPath>
        <LinearGradient
          id="linear-gradient"
          x1={-510.41}
          y1={478.53}
          x2={-510.24}
          y2={478.53}
          gradientTransform="matrix(-78.33 0 0 78.33 -39944.59 -37411.29)"
          gradientUnits="userSpaceOnUse">
          <Stop offset={0} stopColor="#3ac6f4" />
          <Stop offset={1} stopColor="#1172ba" />
        </LinearGradient>
        <ClipPath id="clippath-5">
          <Path fill="none" d="M10.9 77.23H25.439999999999998V90.84H10.9z" />
        </ClipPath>
        <ClipPath id="clippath-6">
          <Path
            fill="none"
            d="M17.76 79.56c-.07-.79-.06-1.56 0-2.33-2.46.9-4.79 2.21-6.86 3.97a13.73 13.73 0 004.3 6.39 13.85 13.85 0 0010.25 3.18c-4.2-2.08-7.25-6.21-7.69-11.22"
          />
        </ClipPath>
        <LinearGradient
          id="linear-gradient-2"
          x1={-512.29}
          y1={479.1}
          x2={-512.11}
          y2={479.1}
          gradientTransform="matrix(-52.1 0 0 52.1 -26664.88 -24874.79)"
          gradientUnits="userSpaceOnUse">
          <Stop offset={0} stopColor="#12abe3" />
          <Stop offset={1} stopColor="#72cddd" />
        </LinearGradient>
        <ClipPath id="clippath-7">
          <Path
            fill="none"
            d="M17.76 77.23c-.06.76-.07 1.54 0 2.33.44 5 3.49 9.14 7.69 11.22a13.866 13.866 0 0011.27-7.97 22.54 22.54 0 00-5.67-6.28c-1.8-.45-3.64-.68-5.48-.68-2.66 0-5.3.47-7.81 1.39"
          />
        </ClipPath>
        <LinearGradient
          id="linear-gradient-3"
          x1={-510.85}
          y1={478.32}
          x2={-510.67}
          y2={478.32}
          gradientTransform="matrix(-84.91 0 0 84.91 -43343.61 -40531.67)">
          <Stop offset={1} stopColor="#12abe3" />
          <Stop offset={0} stopColor="#72cddd" />
        </LinearGradient>
      </Defs>
      <Path
        d="M50.51 65.76c2.48 0 4.5.58 4.5.58l-.35 1.64c-6.37-1.08-7.28-.21-7.28 5.29s1.37 6.33 7.52 4.91l.25 1.56s-1.77.81-4.68.81c-4 0-5.27-1.46-5.27-7.14 0-6.08 1.4-7.64 5.29-7.64M62.14 69.22c3.18 0 4.25.73 4.25 3.06 0 3.14-.79 3.54-4.04 3.52h-2.79c.04.66.1 1.02.1 1.02.29 1.85.75 2.62 6.22 1.67l.29 1.41s-1.62.66-4.33.66c-3.14 0-4.35-1.29-4.35-5.68 0-3.81.11-5.66 4.66-5.66m-.17 4.83c2.23.02 2.52-.1 2.52-1.42 0-1.42-.25-1.6-2.46-1.6-1.98 0-2.52.4-2.52 2.06 0 .35-.02.64 0 .94l2.46.02zM70.24 69.39l.73 1.04c.65-.48 1.91-1.21 3.62-1.21 2.43 0 3.89 1.31 3.89 3.73v7.39h-2.1v-6.78c0-1.73-.71-2.31-2.12-2.31-.83 0-2.06.23-3.08.87v8.22H69.1V69.39h1.14zM84.56 71.07v5.62c0 2.29.29 2.1 3.12 2.1l-.13 1.52c-4.33.71-5-.37-5-2.14v-7.1h-1.73V69.4h1.73v-3.08h2.02v3.08h3.58L88 71.07h-3.43zM91 69.39l.56 1.19c.69-.44 2.33-1.39 3.58-1.39 1.06 0 2.08.81 2.08.81l-.83 1.6s-.6-.29-1.73-.29c-.79 0-2 .69-2.75 1.14v7.89h-2.08V69.39H91zM108.4 74.55c0 4.5-1.52 5.99-4.81 5.99-3.54 0-4.91-1.29-4.91-5.99 0-4.02 1.67-5.33 4.91-5.33s4.81 1.37 4.81 5.33m-2.06 0c0-2.98-.69-3.43-2.75-3.43s-2.79.37-2.79 3.43c0 3.46.71 4.22 2.81 4.22s2.73-.85 2.73-4.22M113.29 67.91h-2.19v-1.98h2.19v1.98zm-.06 12.47h-2.08V69.41h2.08v10.97zM124.56 80.36l-.6-.86c-.33.29-.79.61-1.48.79-.69.19-.94.21-2.16.25-2.81 0-4.35-1.5-4.35-5.81 0-3.69 1.71-5.49 4.5-5.49 1.46 0 2.5.41 3.18.81v-4.12h2.08v14.43h-1.17zm-.9-2.16v-6.25c-.71-.37-1.77-.79-2.98-.79-1.77 0-2.62.88-2.62 3.64 0 2.98.64 3.94 2.56 3.94 1.37 0 2.44-.31 3.04-.54"
        fill="#2baae2"
      />
      <Path
        fill="#989898"
        d="M45.82 86.33c.01.2.06.36.14.48.15.23.42.35.8.35.17 0 .33-.03.47-.08.27-.1.41-.27.41-.53 0-.19-.06-.32-.17-.41-.12-.08-.3-.15-.55-.21l-.46-.11c-.3-.07-.51-.15-.64-.23-.22-.15-.32-.37-.32-.65 0-.31.11-.57.32-.77.21-.2.51-.3.9-.3.36 0 .66.09.91.27.25.18.37.46.37.85h-.44c-.02-.19-.07-.33-.15-.43-.14-.18-.37-.28-.7-.28-.27 0-.46.06-.58.18-.12.12-.17.25-.17.41 0 .17.07.3.21.38.09.05.3.11.62.19l.47.11c.23.05.41.13.53.22.22.16.32.4.32.71 0 .39-.14.66-.41.83-.28.17-.59.25-.96.25-.42 0-.75-.11-.99-.33-.24-.22-.36-.52-.35-.9h.44zM58.21 84.46c.23.31.35.7.35 1.19 0 .52-.13.95-.4 1.3-.31.41-.75.61-1.33.61-.54 0-.96-.18-1.27-.53-.27-.34-.41-.78-.41-1.3 0-.47.12-.88.35-1.21.3-.43.75-.65 1.34-.65s1.07.2 1.37.59m-.42 2.23c.19-.3.28-.64.28-1.03 0-.41-.11-.74-.32-.99-.21-.25-.51-.37-.88-.37s-.66.12-.88.37c-.23.25-.34.61-.34 1.1 0 .39.1.71.29.98.2.27.51.4.95.4s.72-.15.91-.45"
      />
      <Path
        fill="#989898"
        d="M65.77 83.96L66.24 83.96 66.24 87.04 67.99 87.04 67.99 87.46 65.77 87.46 65.77 83.96z"
      />
      <Path
        fill="#989898"
        d="M75.47 83.96v2.16c0 .25.05.46.14.63.14.25.38.38.72.38.4 0 .68-.14.82-.41.08-.15.12-.35.12-.6v-2.16h.48v1.96c0 .43-.06.76-.17.99-.21.42-.62.63-1.21.63s-.99-.21-1.21-.63c-.12-.23-.17-.56-.17-.99v-1.96h.48z"
      />
      <Path
        fill="#989898"
        d="M87.64 83.96L87.64 84.38 86.46 84.38 86.46 87.46 85.99 87.46 85.99 84.38 84.81 84.38 84.81 83.96 87.64 83.96z"
      />
      <Path fill="#989898" d="M94.75 83.96H95.23V87.44999999999999H94.75z" />
      <Path
        fill="#989898"
        d="M105.52 84.46c.23.31.35.7.35 1.19 0 .52-.13.95-.4 1.3-.31.41-.75.61-1.33.61-.54 0-.96-.18-1.27-.53-.27-.34-.41-.78-.41-1.3 0-.47.12-.88.35-1.21.3-.43.75-.65 1.34-.65s1.07.2 1.37.59m-.42 2.23c.19-.3.28-.64.28-1.03 0-.41-.11-.74-.32-.99-.22-.25-.51-.37-.88-.37s-.66.12-.89.37c-.23.25-.34.61-.34 1.1 0 .39.1.71.29.98.2.27.51.4.95.4s.72-.15.91-.45"
      />
      <Path
        fill="#989898"
        d="M113.08 83.96L113.64 83.96 115.38 86.79 115.38 83.96 115.83 83.96 115.83 87.46 115.3 87.46 113.53 84.64 113.53 87.46 113.08 87.46 113.08 83.96z"
      />
      <Path
        fill="#989898"
        d="M123.5 86.33c.01.2.06.36.13.48.15.23.42.35.8.35.17 0 .33-.03.47-.08.27-.1.41-.27.41-.53 0-.19-.06-.32-.17-.41-.12-.08-.3-.15-.55-.21l-.46-.11c-.3-.07-.51-.15-.63-.23-.22-.15-.32-.37-.32-.65 0-.31.11-.57.32-.77.21-.2.51-.3.9-.3.36 0 .66.09.91.27.25.18.37.46.37.85h-.45c-.02-.19-.07-.33-.15-.43-.14-.18-.37-.28-.7-.28-.27 0-.46.06-.58.18-.12.12-.17.25-.17.41 0 .17.07.3.21.38.09.05.29.11.62.19l.47.11c.23.05.41.13.53.22.21.16.32.4.32.71 0 .39-.14.66-.41.83-.27.17-.59.25-.96.25-.43 0-.76-.11-1-.33-.24-.22-.36-.52-.35-.9h.44z"
      />
      <Path fill="#ec2227" d="M37.64 74.06v0" />
      <Path fill="#8dc641" d="M36.57 70.47c.03.09.07.17.1.26-.03-.09-.07-.17-.1-.26" />
      <G clipPath="url(#clippath-4)">
        <Path
          transform="rotate(-45 28.945 71.98)"
          d="M16.81 59.85H41.08V84.12H16.81z"
          fill="url(#linear-gradient)"
        />
      </G>
      <Path
        fill="#932a8e"
        d="M36.72 82.8c.5-1.08.85-2.21 1.06-3.36a22.023 22.023 0 00-6.73-2.92c2.25 1.71 4.18 3.84 5.67 6.28"
      />
      <Path
        d="M36.72 82.8c.5-1.08.85-2.21 1.06-3.36a22.023 22.023 0 00-6.73-2.92c2.25 1.71 4.18 3.84 5.67 6.28M19.25 71.99c-1.26-.1-2.53-.11-3.83 0-1.58.14-3.11.45-4.57.89-.83 2.72-.8 5.63.06 8.31 2.07-1.76 4.4-3.08 6.86-3.97.15-1.86.66-3.64 1.49-5.24"
        fill="#1172ba"
      />
      <Path
        fill="#932a8e"
        d="M17.76 79.56c-.07-.79-.06-1.56 0-2.33-2.46.9-4.79 2.21-6.86 3.97a13.73 13.73 0 004.3 6.39 13.85 13.85 0 0010.25 3.18c-4.19-2.08-7.25-6.21-7.69-11.22"
      />
      <G opacity={0.6}>
        <G clipPath="url(#clippath-5)">
          <G clipPath="url(#clippath-6)">
            <Path
              transform="rotate(-45 18.179 84.18)"
              d="M8.12 74.12H28.22V94.22H8.12z"
              fill="url(#linear-gradient-2)"
            />
          </G>
        </G>
      </G>
      <Path
        fill="#ec2227"
        d="M31.05 76.52a22.514 22.514 0 00-11.81-4.53c-.82 1.6-1.34 3.37-1.49 5.24 4.25-1.55 8.89-1.81 13.29-.71"
      />
      <Path
        d="M31.05 76.52a22.514 22.514 0 00-11.81-4.53c-.82 1.6-1.34 3.37-1.49 5.24 4.25-1.55 8.89-1.81 13.29-.71"
        fill="#70cddd"
      />
      <G clipPath="url(#clippath-7)">
        <Path
          transform="rotate(-45 27.208 83.31)"
          d="M15.2 71.31H39.21V95.32000000000001H15.2z"
          fill="url(#linear-gradient-3)"
        />
      </G>
      <Path
        fill="#8dc641"
        d="M37.64 74.06c-.25-1.15-.57-2.27-.98-3.34.19.5.36 1.02.51 1.53.18.6.33 1.2.46 1.81M36.06 69.25"
      />
      <Path
        d="M13.48 68.04a13.742 13.742 0 00-2.63 4.86c1.46-.44 2.99-.75 4.57-.89 1.3-.11 2.57-.11 3.83 0 2.09-4.06 6.14-7.01 11.02-7.47a13.88 13.88 0 00-7.09-1.41l-.26.03c-.56.08-1.11.17-1.65.28h-.03c-.61.13-1.21.28-1.81.46-2.24.8-4.3 2.19-5.94 4.14"
        fill="#ed2224"
      />
    </Svg>
  );
};

export default Logo;
