import throttle from 'lodash/throttle';
export class SortableList<T> {
  private arr = [] as T[];
  private listeners = new Map<number, (n: T[]) => void>();
  asc = true;
  getValue: (n: T) => Number;

  onUpdate() {
    const shallowCopy = [...this.arr];
    this.listeners.forEach(listener => listener(shallowCopy));
  }

  listen = (cb: (n: T[]) => void) => {
    let id = Date.now();
    this.listeners.set(id, cb);
    return () => {
      this.listeners.delete(id);
    };
  };

  constructor(getValue: SortableList<T>['getValue'], data?: T[], throttleMs = 250) {
    if (data) {
      data.forEach(n => this.push(n));
    }
    this.getValue = getValue;
    this.onUpdate = throttle(this.onUpdate.bind(this), throttleMs);
    this.update = this.update.bind(this);
  }

  get data() {
    return this.arr;
  }
  get length() {
    return this.arr.length;
  }
  push(n: T, skip = false) {
    const {getValue: v} = this;
    let next = this.arr.findIndex(row => {
      const isBigger = v(row) > v(n);
      return isBigger === this.asc;
    });

    if (next === 0) {
      this.arr = [n, ...this.arr];
    } else if (next === -1) {
      this.arr = this.arr.concat([n]);
    } else {
      // in middle
      let prefix = this.arr.slice(0, next);
      let suffix = this.arr.slice(next);
      this.arr = [...prefix, n, ...suffix];
      skip || this.onUpdate();
      return prefix.length;
    }

    skip || this.onUpdate();
    return this.arr.length - 1;
  }

  update(nodeOrIndex: T | number) {
    let idx =
      typeof nodeOrIndex === 'number'
        ? nodeOrIndex
        : this.arr.findIndex(row => row === nodeOrIndex);
    if (idx === -1) {
      throw new Error('item not in list');
    }

    const node = this.arr[idx];
    const prev = this.arr[idx - 1];
    const me = this.arr[idx];
    const next = this.arr[idx + 1];

    if (!me) {
      throw new Error('Array corrupted');
    }

    // --- do i need to move ?
    const biggerThanPrev = !prev || this.getValue(me) >= this.getValue(prev);
    const lessThanNext = !next || this.getValue(me) < this.getValue(next);

    if (biggerThanPrev && lessThanNext) {
      return;
    }

    this.arr.splice(idx, 1);
    this.push(node);
  }
}
