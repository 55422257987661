function _shift(x) {
  var parts = x.toString().split('.');
  return parts.length < 2 ? 1 : Math.pow(10, parts[1].length);
}
function _cf() {
  return Array.prototype.reduce.call(
    arguments,
    function (prev, next) {
      return prev === undefined || next === undefined ? undefined : Math.max(prev, _shift(next));
    },
    -Infinity
  );
}

/**
 * Fraction Safe addition
 * @returns {number}
 */

module.exports.sum = function () {
  var f = _cf.apply(null, arguments);
  if (f === undefined) return undefined;
  function cb(x, y, _, __) {
    return x + f * y;
  }
  return Array.prototype.reduce.call(arguments, cb, 0) / f;
};

/**
 * Fraction Safe subtract
 * @returns {number}
 */

module.exports.subtract = function (l, r) {
  var f = _cf(l, r);
  return (l * f - r * f) / f;
};

/**
 * Fraction Safe Multiply
 * @returns {number}
 */
module.exports.multiply = function () {
  var f = _cf.apply(null, arguments);
  function cb(x, y, _, __) {
    return (x * f * (y * f)) / (f * f);
  }
  return Array.prototype.reduce.call(arguments, cb, 1);
};

/**
 * Fraction Safe Devision
 * @returns {number}
 */
module.exports.divide = function (l, r) {
  var f = _cf(l, r);
  return (l * f) / (r * f);
};
