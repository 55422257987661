import {IConfig} from '.';
import Logo from './logos/gfc';
import {GFCLogo} from '@/components/HorizontalLogo/GFCLogo';

const config: IConfig = {
  app_name: 'GFC',
  logo: props => <Logo width={props?.width || 250} />,
  horizontal_logo: GFCLogo,
  languages: ['en', 'es'],
  default_language: 'en',
  theme: {
    primary: '#3E89C3',
    primary_pressed: '#9ec4e1',
  },
  features: {
    isLeverageEnabled: false,
    isNotificationsEnabled: true,
    isSignupEnabled: false,
    isLanguageEnabled: true,
    isMTLoginEnabled: true,
    isSocialLoginEnabled: false,
    isDepositEnabled: false,
    isInternalTransferEnabled: true,
    isStatmentEnabled: false,
    isSupportEnabled: false,
    isSettingsEnabled: true,
    isNewsEnabled: false,
    isEventsEnabled: false,
    isContentCardsEnabled: false,
    isDailyUpdatesEnabled: false,
    isRecurringDepositEnabled: false,
    isTermsAndConditionsEnabled: false,
    isAppTourEnabled: false,
    isUAEPassEnabled: false,
    isWithdrawEnabled: false,
    isStaticIcons: true,
    isTradingViewChartUsed: false,
  },
  navigation: {
    Foryou: {
      isMain: true,
      isShown: true,
      icon: {
        name: 'Home',
        size: 30,
      },
    },
    Watchlist: {
      isMain: false,
      isShown: true,
      icon: {
        name: 'Watchlist',
        size: 30,
      },
    },
    Portfolio: {
      isMain: false,
      isShown: true,
      icon: {
        name: 'Dollar',
        size: 30,
      },
    },
    Plus: {
      isMain: false,
      isShown: false,
      icon: {
        name: 'Plus',
        size: 30,
      },
    },
    Profile: {
      isMain: false,
      isShown: true,
      icon: {
        name: 'Profile',
        size: 30,
      },
    },
  },
};

export default config;
