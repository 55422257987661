import {useQuery, UseQueryResult} from '@tanstack/react-query';
import axios from 'axios';

axios.defaults.timeout = 30000;
export const rvApi = axios.create({
  baseURL: 'https://idwise.amana.app/idwise/api/real-vission',
});
rvApi.defaults.headers.common.Authorization =
  'Basic cmVhbC12aXNzaW9uOkFtcFlzOTVRVUsxQmozUFdySGVXMjI=';
export type RV_Routes =
  | 'daily-briefing'
  | 'crypto-verse'
  | 'crypto-adventures'
  | 'crypto-daily-briefing';
// | 'investor-tutorial';

export function useRealVision(type: RV_Routes): UseQueryResult<RV[], unknown>;
export function useRealVision(
  type: 'investor-tutorial' | 'real-investing-course' | 'crypto-adventures'
): UseQueryResult<RVCourse[], unknown>;
export function useRealVision(
  type: RV_Routes | 'investor-tutorial' | 'real-investing-course' | 'crypto-adventures'
) {
  const q = useQuery({
    queryKey: [type] as const,
    queryFn: params =>
      rvApi
        .get<RV[] | RVCourse[]>('/' + type, {
          params: {limit: 20, page: params.queryKey[0]},
        })
        .then(r => r.data),
    keepPreviousData: true,
  });

  return q;
}

export function useWalid() {
  return useQuery({
    queryKey: ['walid'],
    queryFn: () => rvApi.get('/walid-videos'),
  });
}

export interface RV {
  Title: string;
  Thumbnails: string[];
  Videos: Video[];
  Updated: string;
  Link: string;
  PubDate: string;
  EnglishSubtitle?: string;
  ArabicSubtitle?: string;
  Description: string;
  Credit: string;
  Keywords: string;
  ContentId: string;
}

interface Video {
  url: string;
  type: string;
  height: string;
  width: string;
  bitrate: string;
  duration: string;
}

export interface RVCourse {
  Category: string;
  CategorySorting: number;
  CategoryImage: string;
  videosCount: number;
  videosDurationInMinutes: string;
  hasSubCategories: boolean;
  subCategories: SubCategory[];

  videos: Video2[];
}

interface SubCategory {
  SubCategory: string;
  SubCategorySorting: number;
  videosCount: number;
  videosDurationInMinutes: string;
  videos: Video2[];
}

export interface Video2 {
  ContentId: string;
  Title: string;
  Thumbnails: string[];
  Videos: Video[];
  Link: string;
  PubDate: string;
  EnglishSubtitle?: string;
  ArabicSubtitle?: string;
  Updated: string;
  Description: string;
  Credit: string;
  Keywords: string;
  DurationInMinutes: string;
  Sorting: number;
}

interface Video {
  url: string;
  type: string;
  height: string;
  width: string;
  bitrate: string;
  duration: string;
}
