import {tryParseJson} from './helpers';
import {BankCard} from './types';
import {AxiosInstance} from 'axios';
import {MMKV} from 'react-native-mmkv';

/**
 * Temoprary storage that gets cleared once user logsout
 */
export const storage = new MMKV({
  id: 'general-storages',
  // path: `${USER_DIRECTORY}/storage`,
  // encryptionKey: 'hunter2'
});

/**
 * A storage that never get cleared.
 * please use this sstorage for permentant memory stuff that should exsists between logins
 */
export const appSettings = new MMKV({
  id: 'app-settings-storage',
  // path: `${USER_DIRECTORY}/storage`,
  // encryptionKey: 'hunter2'
});

/**
 * Private Temorary
 * Storage To be used via `storeCache`, `deleteCache`, and `getCache` only.
 * Mainly used for Cached HTTP requests and saving data for specific TTL
 */
const ttlStorage = storage;

export const paymentStorage = new MMKV({
  id: 'payment-storage',
});

export const addBankCard = (card: BankCard) => {
  const bankCards = getBankCards();
  setActiveCard(card);
  paymentStorage.set('bankCards', JSON.stringify([...bankCards, card]));
};

export const getBankCards = () => {
  return JSON.parse(paymentStorage.getString('bankCards') || '[]') as BankCard[];
};

export const setActiveCard = (card: BankCard) => {
  paymentStorage.set('activeCard', JSON.stringify(card));
};

export const getActiveCard = () => {
  return JSON.parse(paymentStorage.getString('activeCard') || '{}') as BankCard;
};

export const removeCard = (cardNumber: string) => {
  const cards = getBankCards();
  const cardIndex = cards.findIndex(card => card.cardNumber === cardNumber);
  delete cards[cardIndex];
  paymentStorage.set('bankCards', JSON.stringify(cards));
};

export const storeCache = <t = unknown>(key: string, value: t): t => {
  const item = {
    value,
    timeStamp: Date.now(),
  };

  try {
    ttlStorage.set(key, JSON.stringify(item));
  } catch (err) {
    console.error(err);
  }
  return value;
};

const getDiffInMinutes = (item: {timestamp: number}) => {
  const now = Date.now();
  const storedTime = new Date(item.timestamp);
  const diff = now - storedTime.getTime();
  const diffInMinutes = Math.round(diff / (1000 * 60));
  return diffInMinutes;
};

export const deleteCache = (key: string) => ttlStorage.delete(key);

export const getCache = <t = unknown>(key: string, expiryInMinutes = 5): t | null => {
  try {
    const value = ttlStorage.getString(key);
    if (!value) {
      return null;
    }

    const item = JSON.parse(value);

    if (!item) {
      return null;
    }

    if (getDiffInMinutes(item) > expiryInMinutes) {
      storage.delete(key);
      return null;
    }

    return item.value;
  } catch (err) {
    storage.delete(key);
    return null;
  }
};

export const offlineFirstDownload = async <T>(
  url: string,
  expiryInMinutes = 60,
  cb: (data: T) => any,
  forceRevalidate = false
) => {
  let current = getCache<T>(url, expiryInMinutes);
  if (current) {
    if (typeof current !== 'string') {
      cb(current);
      return current;
    }
    const data = tryParseJson(current);
    if (data) {
      cb(data);
      return data;
    }
  }
  return fetch(url)
    .then(res => (res.status === 200 ? res.text() : Promise.reject(res)))
    .then(text => {
      storeCache(url, text);
      if (forceRevalidate) {
        const json = JSON.parse(text);
        cb(json);
      }
      return;
    });
};

let throttle = {} as Record<string, Promise<any>>;
export const fetchFromCache = <T>(
  key: string,
  fn: AxiosInstance,
  expiryInMinutes = 60,
  dontRefresh = false
): Promise<T> => {
  const str = getCache<T>(key, expiryInMinutes);
  if (str && dontRefresh) return Promise.resolve(str);
  throttle[key] =
    throttle[key] ||
    fn
      .get<T>(key)
      .then(r => {
        setTimeout(() => storeCache(key, r?.data), 0);
        return r?.data;
      })
      .finally(() => {
        delete throttle[key];
      });

  return str ? Promise.resolve(str) : throttle[key];
};
