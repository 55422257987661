import {useState, useEffect} from 'react';

function makeHook<T = string>(method: keyof MMKV) {
  return (key: string, mmkv = defaultMMKV) => {
    const [state, setState] = useState<T>(mmkv[method](key));

    useEffect(() => mmkv.addListener(key, () => setState(mmkv[method](key))), [key, mmkv]);
    useEffect(() => {
      mmkv.set(key, state);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    return [state, setState] as const;
  };
}

export const useMMKVBoolean = makeHook<boolean>('getBoolean');
export const useMMKVString = makeHook<string>('getString');
export const useMMKVNumber = makeHook<number>('getNumber');

export class MMKV {
  public readonly id: string;
  engine: any; // LocalStorage | SessionStorage
  constructor(props: {id: string; encryptionKey?: string}) {
    console.debug('MMKV: constructor', props.id);
    this.id = props.id;
    this.engine = props.encryptionKey ? sessionStorage : localStorage;
  }
  clearAll = () => {
    Object.keys(this.engine).forEach(key => {
      if (key.startsWith(this.id)) {
        this.delete(key);
      }
    });
  };
  _get(key: string) {
    return this.id + '_id_' + key;
  }
  getString(key: string, defaultValue: string = ''): string {
    return this.engine.getItem(this._get(key)) || defaultValue;
  }
  getBoolean(key: string, defaultValue: boolean = false): boolean {
    const value = this.engine.getItem(this._get(key)) || defaultValue;
    return value === 'true';
  }
  getNumber(key: string): number {
    return Number(this.engine.getItem(this._get(key)));
  }
  set(key: string, value: unknown) {
    const _key = this._get(key);
    switch (typeof value) {
      case 'string':
        this.engine.setItem(_key, value);
        break;
      case 'boolean':
        this.engine.setItem(_key, value ? 'true' : 'false');
        break;
      case 'number':
        this.engine.setItem(_key, value.toString());
        break;
      case 'object':
        this.engine.setItem(_key, JSON.stringify(value));
        break;
      default:
        console.warn('MMKV: set: unknown type', typeof value);
    }
    this.emit(key);
  }
  private __listeners = {} as Record<string, Set<() => void>>;
  addListener(key: string, cb: () => void) {
    this.__listeners[key] = this.__listeners[key] || new Set();
    this.__listeners[key].add(cb);
    return () => {
      this.__listeners[key].delete(cb);
    };
  }
  emit(key: string) {
    this.__listeners[key] = this.__listeners[key] || new Set();
    this.__listeners[key].forEach(cb => cb());
  }
  delete(key: string) {
    const _key = this._get(key);
    this.engine.removeItem(_key);
    this.emit(key);
  }
}

const defaultMMKV = new MMKV({id: 'general-storages'});
