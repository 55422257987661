export default class appsFlyer {
  static async logEvent(_a: string, _b: {method: string}) {
    return '';
  }

  static async setCustomerUserId(_a: any) {
    return '';
  }

  static async setUserEmails(_a: any, _b: any, _c: any) {
    return '';
  }
}
