import {firebaseApp} from './index.web';
import {vapidKey} from '@/Env/index.web';
import {getMessaging, getToken, isSupported} from 'firebase/messaging';

export const messaging = isSupported().then(val => (val ? getMessaging(firebaseApp) : null));

export const getFbToken = async () => {
  const m = await messaging;
  if (!m) return null;

  return getToken(m, {
    vapidKey: vapidKey,
  })
    .then(currentToken => {
      if (currentToken) {
        console.log('Current FCM token', currentToken);
        return currentToken;
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch(err => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

export const requestPermission = async () => {
  console.log('Requesting permission...');
  return getFbToken();
};

export const logScreenView = (name: string) => {
  console.debug('fb. logScreenView NO IMPLEMENTED:' + name);
  return () => null;
};
