import {useT} from '@/Lib';
import {Text, useAppTheme, useStyle, View} from '@amana/ui';
import {useNetInfo} from '@react-native-community/netinfo';
import {memo, useEffect} from 'react';
import FastImage from 'react-native-fast-image';

export const AnimatedSpinner = memo(
  () => {
    const [theme] = useAppTheme();
    useEffect(() => {
      return () => {
        FastImage.clearMemoryCache();
      };
    }, []);
    const S = useStyle();
    const net = useNetInfo();
    const t = useT();
    return (
      <View
        bg="bg"
        style={[
          S.flex,
          S.justify,
          S.align,
          S.pad4,
          {
            gap: 120,
            minHeight: '30%',
          },
        ]}>
        <FastImage
          resizeMode="contain"
          style={{
            width: '100%',
            height: '100%',
            maxWidth: 300,
            maxHeight: 300,
          }}
          source={
            theme === 'dark'
              ? require('@/../assets/animation/login-dark.gif')
              : require('@/../assets/animation/login-light.gif')
          }
        />

        {net.isInternetReachable === false ? (
          <Text color="orange">{t.internetConnection.title}</Text>
        ) : null}
      </View>
    );
  },
  () => true
);
