import {UAEPASS_BASE_URL, UAEPASS_CLIENT_ID, UAEPASS_SCOPE, UAEPASS_WEB_REDIRECT_URI} from '@/Env';

export const openPopup = (): Promise<string> => {
  document.cookie = `origin=${window.location.host}; path=/`;
  const url = `${UAEPASS_BASE_URL}/idshub/authorize?response_type=code&client_id=${UAEPASS_CLIENT_ID}&scope=${UAEPASS_SCOPE}&state=HnlHOJTkTb66Y5H&redirect_uri=${UAEPASS_WEB_REDIRECT_URI}&acr_values=urn:safelayer:tws:policies:authentication:level:low `;
  return new Promise(resolve => {
    const width = 600,
      height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    const popup = window.open(
      `https://mobilebackend.amanalabs.net/api/v1/auth/provider/uaepass/${window.location.host}`,
      'OAuthPopup',
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`
    );
    setTimeout(() => {
      document.cookie = `origin=${window.location.host}; path=/`;
      // @ts-ignore
      popup.location = url;
    }, 1000);

    const handleMessage = (event: any) => {
      if (event.data.authCode) {
        resolve(event.data.authCode);
      }
    };

    window.addEventListener('message', handleMessage, false);
  });
};
